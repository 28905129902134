/* eslint-disable no-param-reassign */
import {
  LOGIN,
  LOGOUT,
  ASYNC_START,
  ASYNC_END,
  LOGGED_OUT,
} from './constants/actionTypes';
import storage from '../utils/storage';
import agent from '../agent';

function isPromise(v) {
  return v && typeof v.then === 'function';
}

const promiseMiddleware = (store) => (next) => (action) => {
  if (isPromise(action.payload)) {
    store.dispatch({ type: ASYNC_START, subtype: action.type });

    action.payload.then(
      (payload) => {
        action.payload = payload;
        store.dispatch({ type: ASYNC_END, subtype: action.type });
        store.dispatch(action);
      },
      (error) => {
        action.error = true;
        // response is undefined if there is network error
        action.payload = error.response ? error.response.data : {};

        store.dispatch({ type: ASYNC_END, subtype: action.type });
        store.dispatch(action);

        throw error;
      },
    );

    return;
  }

  next(action);
};

/*
  For handling of access / refresh tokens
 */
// eslint-disable-next-line no-unused-vars
const localStorageMiddleware = (store) => (next) => (action) => {
  if (action.type === LOGIN) {
    if (!action.error) {
      storage.setAccesToken(
        action.payload.access_token,
        action.payload.exp,
      );
      storage.setRefreshToken(action.payload.refresh_token);
      agent.setToken(action.payload.access_token);
    }
  } else if (action.type === LOGOUT || action.type === LOGGED_OUT) {
    storage.clearAccesToken();
    storage.clearRefreshToken();
    agent.setToken(null);
  }

  next(action);
};

export { localStorageMiddleware, promiseMiddleware };

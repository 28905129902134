/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ConfigProvider } from 'antd';

import validateMessages from './utils/validateMessages';

import * as serviceWorker from './serviceWorker';

import './index.less';

import App from './App';

import configureStore, { history } from './store';

const store = configureStore({});

const renderApp = () => (
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <Provider store={store} context={ReactReduxContext}>
      <ConnectedRouter history={history} context={ReactReduxContext}>
        <ConfigProvider
          form={{ validateMessages }}
        >
          <App />
        </ConfigProvider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
  )
);

// if (process.env.NODE_ENV !== 'production' && module.hot) {
//   module.hot.accept('./components', renderApp)
// }

// Hot reloading
if (process.env.NODE_ENV !== 'production' && module.hot) {
  // Reload components
  module.hot.accept('./App', () => {
    // eslint-disable-next-line no-console
    console.log('hot reloading');
    renderApp();
  });
}

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

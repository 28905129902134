import {
  UPDATE_ALPHA_BUILD,
  ALPHA_BUILD_STATUS_CHECK_SUCCESS,
  ALPHA_BUILD_RESET_STATUS,
  ALPHA_BUILDS_FETCHED_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  alphaBuilds: [],
  status: true,  // Status indicating wether the is_already_upload is true/false on every API check call.
  timerIdx: 0,   // Current index of timer which has been executed for the API check call.
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ALPHA_BUILD:
      return {
        ...state,
        errors: action.payload.errors,
        message: action.payload.msg,
      };
    case ALPHA_BUILDS_FETCHED_SUCCESS:
      return {
        ...state,
        alphaBuilds: action.payload.alphaBuilds.map((item) => {
          // eslint-disable-next-line no-underscore-dangle
          const newItem = { ...item, id: item.id };
          return newItem;
        }),
        pager: action.payload.pager,
      };

    case ALPHA_BUILD_STATUS_CHECK_SUCCESS:
      return {
        ...state,
        status: action.payload.buildData.is_already_upload,
        timerIdx: action.payload.timerIdx,
      };

    case ALPHA_BUILD_RESET_STATUS:
      return {
        ...state,
        status: true,
        timerIdx: 0,
      };
    default:
      return state;
  }
};

import {
  CUSTOM_CATEGORY_FETCHED_SUCCESS,
  UNLOAD_CUSTOM_CATEGORY,
  ADD_CUSTOM_CATEGORY,
  UPDATE_CUSTOM_CATEGORY,
  PUSHED_CUSTOM_CATEGORY,
} from '../constants/actionTypes';

const initialState = {
  customCategories: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_CUSTOM_CATEGORY:
    case UPDATE_CUSTOM_CATEGORY:
      return {
        ...state,
        message: action.payload.msg,
      };

    case UNLOAD_CUSTOM_CATEGORY:
      return initialState;

    case CUSTOM_CATEGORY_FETCHED_SUCCESS:
      return {
        ...state,
        customCategories: action.payload.customCategories.map((item) => {
          const newItem = {
            ...item,
            // eslint-disable-next-line no-underscore-dangle
            id: item.id,
            stores_included: item.stores.map((store) => `${store.store.name}, `),
          };
          return newItem;
        }),
        pager: action.payload.pager,
      };

    case PUSHED_CUSTOM_CATEGORY:
      return {
        ...state,
        customCategories: state.customCategories.map((item) => {
          if (item.id === action.payload.id) {
            const newItem = {
              ...item,
            };
            // if (!newItem.modified_at) newItem.modified_at = action.payload.date;
            newItem.pushed_at = action.payload.date;
            return newItem;
          }
          return item;
        }),
      };

    default:
      return state;
  }
};


import {
  CREATE_ARTICLE_CATEGORY,
  UPDATE_ARTICLE_CATEGORY,
  ARTICLE_CATEGORY_FETCH_SUCCESS,
  ARTICLE_CATEGORY_TOGGLE_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  articleCategory: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ARTICLE_CATEGORY:
    case UPDATE_ARTICLE_CATEGORY:
      return {
        ...state,
        message: action.payload.msg,
      };

    case ARTICLE_CATEGORY_FETCH_SUCCESS:
      return {
        ...state,
        articleCategory: action.payload.articleCategory,
        pager: action.payload.pager,
      };


    case ARTICLE_CATEGORY_TOGGLE_SUCCESS:
      return {
        ...state,
        articleCategory: state.articleCategory.map((category) => {
          if (category.id !== action.payload.id) return category;
          const newCategory = { ...category };
          newCategory.is_active = action.payload.active;
          return newCategory;
        }),
      };

    default:
      return state;
  }
};

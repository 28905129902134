import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AndroidOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm, Button } from 'antd';
import agent from '../agent';
import { showSuccessMessage, showErrorMessage } from '../utils/messages';
import { pushTypeOptions } from '../constants/options';
import { pushedResourceSuccessfully } from '../store/actions/commonActions';

const styleBtn = { color: 'red' };

class PushButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { inProgress: false };
  }

  pushToApp = () => {
    const { id, resource } = this.props;

    this.setState({ inProgress: true });

    agent.Push.resource(id, resource)
      .then((res) => {
        showSuccessMessage(`${resource.toLowerCase()} with Id ${id} is successfully pushed to app`);
        if (res.pushed_at) this.props.updateResource(res.pushed_at.replace(' ', 'T'));
      })
      .catch((err) => showErrorMessage(err))
      .finally(() => this.setState({ inProgress: false }));
  }

  render() {
    const { disabled, permission } = this.props;
    const canPush = permission.push && !disabled;

    return (
      <Popconfirm
        title="Are you sure to push this resource to app？"
        icon={<QuestionCircleOutlined style={styleBtn} />}
        placement="right"
        okText="push"
        onConfirm={this.pushToApp}
        disabled={!canPush}
      >
        <Button
          type="danger"
          size="small"
          icon={<AndroidOutlined />}
          loading={this.state.inProgress}
          disabled={!canPush}
          title="Push"
        />
      </Popconfirm>
    );
  }
}

PushButton.defaultProps = {
  disabled: true,
};

PushButton.propTypes = {
  id: PropTypes.string.isRequired,
  resource: PropTypes.oneOf(pushTypeOptions).isRequired,
  updateResource: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  permission: PropTypes.shape({
    push: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  permission: ownProps.permissionKey
    ? state.common.user.permissions[ownProps.permissionKey]
    : { push: true },
  ...ownProps,
});

const mapDispatchToProps = (dispatch, { resource, id }) => ({
  updateResource: (date) => dispatch(pushedResourceSuccessfully(id, date)(`PUSHED_${resource}`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PushButton);

/* eslint-disable import/prefer-default-export */
import { uniqBy } from 'loadsh';
import agent from '../agent';

/**
 * @description - handle ajax search for FormSelect component.
 *                component using handleSearch must have
 *                [searching] { Boolean } and [dataKey] { Array } in the state
 *
 * @param { String } searchType - controller name
 * @param { String } dataKey - name of state variable on which result is going to be stored
 * @param { Object | Function } - dynamic or static extra params
 *
 * @returns { Function } - which accept @param value and set response to @param dateKey
 */

export function handleSearch(searchType, dataKey, params) {
  return (value) => {
    const query = typeof value === 'string' ? value.trim() : '';
    const extra = typeof value === 'object' ? value : {};

    if (query.length > 0 || Object.keys(extra).length) {
      this.setState({ searching: searchType });
      const extraParams = {
        ...(typeof params === 'function' ? params() : params),
        ...extra,
      };


      agent.Search[searchType](query, extraParams)
        .then((res) => {
          this.setState({ [dataKey]: uniqBy(res.data, 'value') });
        })
        .catch(() => {
          this.setState({ [dataKey]: [] });
        })
        .finally(() => this.setState({ searching: false }));
    } else {
      this.setState({ [dataKey]: [] });
    }
  };
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {  QuestionCircleOutlined, SendOutlined } from '@ant-design/icons';
import { Popconfirm, Button } from 'antd';
import { pushTypeOptions } from '../constants/options';

const styleBtn = { color: 'red' };

class ConfirmModalButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: false,
      disabled: false
    };
  }

  pushToApp = () => {
    const { id, record, sendEmail } = this.props;
    this.setState({ inProgress: true });
    record.campaign_status = 'SENT';
    record.is_blocked = true;
    sendEmail(id);
  }

  render() {

    // const { disabled, permission } = this.props;
    // const canPush = permission.push && !disabled;

    let { disabled } = this.state;
    const { record } = this.props
    if(record.campaign_status === 'ACTIVE') {
      this.setState({ disabled: false });
    }else{
      this.setState({ disabled: true});
    }

    return (
      <Popconfirm
        title="Are you sure to send this email?"
        icon={<QuestionCircleOutlined style={styleBtn} />}
        placement="left"
        okText="Send"
        onConfirm={this.pushToApp}
        disabled={disabled}
      >
        <Button
          type="primary"
          size="small"
          icon={<SendOutlined />}
          loading={this.state.inProgress}
          title="Push"
          disabled={disabled}
        />
      </Popconfirm>
    );
  }
}

ConfirmModalButton.defaultProps = {
  disabled: true,
};

ConfirmModalButton.propTypes = {
  id: PropTypes.string.isRequired,
  resource: PropTypes.oneOf(pushTypeOptions).isRequired,
  disabled: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
  }
};


export default connect(mapStateToProps)(ConfirmModalButton);

/*
 * action types
 */
export const APP_LOADED = "APP_LOADED";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";

export const LOGIN = "LOGIN";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESSFULL = "LOGIN_SUCCESSFULL";
export const LOGOUT = "LOGOUT";

export const ASYNC_START = "ASYNC_START";
export const ASYNC_END = "ASYNC_END";

export const LOGGED_IN = "LOGGED_IN";
export const LOGGED_OUT = "LOGGED_OUT";

// =====================================================================
// RESOURCES
// =====================================================================

export const ADD_RESOURCE = "ADD_RESOURCE";
export const UPDATE_RESOURCE = "UPDATE_RESOURCE";
export const UNLOAD_RESOURCE = "UNLOAD_RESOURCE";

export const RESOURCE_TOGGLE_STATUS = "RESOURCE_TOGGLE_STATUS";
export const RESOURCE_TOGGLE_SUCCESS = "RESOURCE_TOGGLE_SUCCESS";
export const RESOURCE_TOGGLE_FAILED = "RESOURCE_TOGGLE_FAILED";

export const FETCH_RESOURCE_LIST = "FETCH_RESOURCE_LIST";
export const RESOURCE_FETCHED_SUCCESS = "RESOURCE_FETCHED_SUCCESS";
export const RESOURCE_FETCHED_FAILED = "RESOURCE_FETCHED_FAILED";
export const RESOURCE_DELETE_SUCCESS = "RESOURCE_DELETE_SUCCESS";
export const RESOURCE_DELETE_FAILED = "RESOURCE_DELETE_FAILED";

// =====================================================================
// POLICIES
// =====================================================================

export const ADD_POLICY = "ADD_POLICY";
export const UPDATE_POLICY = "UPDATE_POLICY";
export const UNLOAD_POLICY = "UNLOAD_POLICY";

export const FETCH_POLOCY_LIST = "FETCH_POLOCY_LIST";
export const POLICY_FETCHED_SUCCESS = "POLICY_FETCHED_SUCCESS";
export const POLICY_FETCHED_FAILED = "POLICY_FETCHED_FAILED";

export const POLICY_TOGGLE_STATUS = "POLICY_TOGGLE_STATUS";
export const POLICY_TOGGLE_SUCCESS = "POLICY_TOGGLE_SUCCESS";
export const POLICY_TOGGLE_FAILED = "POLICY_TOGGLE_FAILED";

export const ATTACH_ROLE_TO_POLICY = "ATTACH_ROLE_TO_POLICY";
export const ATTACH_ROLE_TO_POLICY_SUCCESS = "ATTACH_ROLE_TO_POLICY_SUCCESS";
export const ATTACH_ROLE_TO_POLICY_FAILED = "ATTACH_ROLE_TO_POLICY_FAILED";

// =====================================================================
// ROLES
// =====================================================================

export const ADD_ROLE = "ADD_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const UNLOAD_ROLE = "UNLOAD_ROLE";

export const FETCH_ROLE_LIST = "FETCH_ROLE_LIST";
export const ROLE_FETCHED_SUCCESS = "ROLE_FETCHED_SUCCESS";
export const ROLE_FETCHED_FAILED = "ROLE_FETCHED_FAILED";

export const ROLE_TOGGLE_STATUS = "ROLE_TOGGLE_STATUS";
export const ROLE_TOGGLE_SUCCESS = "ROLE_TOGGLE_SUCCESS";
export const ROLE_TOGGLE_FAILED = "ROLE_TOGGLE_FAILED";

export const ATTACH_USER_TO_ROLE = "ATTACH_USER_TO_ROLE";

// =====================================================================
// USERS
// =====================================================================

export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const UNLOAD_USER = "UNLOAD_USER";

export const FETCH_USER_LIST = "FETCH_USER_LIST";
export const USER_FETCHED_SUCCESS = "USER_FETCHED_SUCCESS";
export const USER_FETCHED_FAILED = "USER_FETCHED_FAILED";

export const USER_TOGGLE_STATUS = "USER_TOGGLE_STATUS";
export const USER_TOGGLE_SUCCESS = "USER_TOGGLE_SUCCESS";
export const USER_TOGGLE_FAILED = "USER_TOGGLE_FAILED";

export const ATTACH_ROLE_TO_USER = "ATTACH_ROLE_TO_USER";

// =====================================================================
// ADVERTISERS
// =====================================================================

export const CREATE_SMS = "CREATE_SMS";
export const UPDATE_SMS = "UPDATE_SMS";
export const UNLOAD_SMS = "UNLOAD_SMS";

export const FETCH_SMS_LIST = "FETCH_SMS_LIST";
export const SMS_FETCHED_SUCCESS = "SMS_FETCHED_SUCCESS";
export const SMS_FETCHED_FAILED = "SMS_FETCHED_FAILED";

export const SEND_SMS = "SEND_SMS";

// =====================================================================
// STORES
// =====================================================================

export const CREATE_STORE = "CREATE_STORE";
export const UPDATE_STORE = "UPDATE_STORE";
export const RESET_STORE = "RESET_STORE";
export const UNLOAD_STORE = "UNLOAD_STORE";

export const PUSHED_STORE = "PUSHED_STORE";

export const FETCH_STORE = "FETCH_STORE";
export const UPDATE_STORE_DATA = "UPDATE_STORE_DATA";
export const STORE_FETCHING_SUCCESS = "STORE_FETCHING_SUCCESS";
export const STORE_FETCHING_FAILED = "STORE_FETCHING_FAILED";

export const FETCH_STORE_LIST = "FETCH_STORE_LIST";
export const STORE_LIST_FETCHED_SUCCESS = "STORE_LIST_FETCHED_SUCCESS";
export const STORE_LIST_FETCHED_FAILED = "STORE_LIST_FETCHED_FAILED";

export const FETCH_ADMITAD_API_OFFER_LIST = "FETCH_ADMITAD_API_OFFER_LIST";
export const FETCH_VCOMM_API_OFFER_LIST = "FETCH_VCOMM_API_OFFER_LIST";

export const API_OFFER_SUCCESS = "API_OFFER_SUCCESS";
export const API_OFFER_FAILED = "API_OFFER_FAILED";

// =====================================================================
// CATEGORIES
// =====================================================================

export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const RESET_CATEGORY = "RESET_CATEGORY";

export const PUSHED_CATEGORY = "PUSHED_CATEGORY";

export const CREATE_SUB_CATEGORY = "CREATE_SUB_CATEGORY";
export const UPDATE_SUB_CATEGORY = "UPDATE_SUB_CATEGORY";
export const RESET_SUB_CATEGORY = "RESET_SUB_CATEGORY";

export const PUSHED_SUB_CATEGORY = "PUSHED_SUB_CATEGORY";

export const UNLOAD_CATEGORY = "UNLOAD_CATEGORY";

export const FETCH_CATEGORY_LIST = "FETCH_CATEGORY_LIST";
export const CATEGORY_FETCHED_SUCCESS = "CATEGORY_FETCHED_SUCCESS";
export const CATEGORY_FETCHED_FAILED = "CATEGORY_FETCHED_FAILED";

export const FETCH_SUB_CATEGORY_LIST = "FETCH_CATEGORY_LIST";
export const SUB_CATEGORY_FETCHED_SUCCESS = "SUB_CATEGORY_FETCHED_SUCCESS";
export const SUB_CATEGORY_FETCHED_FAILED = "SUB_CATEGORY_FETCHED_FAILED";

// =====================================================================
// OFFERS
// =====================================================================

export const CREATE_OFFER = "CREATE_OFFER";
export const UPDATE_OFFER = "UPDATE_OFFER";
export const RESET_OFFER = "RESET_OFFER";
export const UNLOAD_OFFER = "UNLOAD_OFFER";

export const PUSHED_OFFER = "PUSHED_OFFER";

export const FETCH_OFFER_LIST = "FETCH_OFFER_LIST";
export const OFFER_FETCHED_SUCCESS = "OFFER_FETCHED_SUCCESS";
export const OFFER_FETCHED_FAILED = "OFFER_FETCHED_FAILED";

export const UPDATE_ACTION_POOL = "UPDATE_ACTION_POOL";

// =====================================================================
// TAGS
// =====================================================================

export const CREATE_TAG = "CREATE_TAG";
export const UPDATE_TAG = "UPDATE_TAG";
export const UNLOAD_TAG = "UNLOAD_TAG";

export const PUSHED_TAG = "PUSHED_TAG";

export const FETCH_TAG_LIST = "FETCH_TAG_LIST";
export const TAG_FETCHED_SUCCESS = "TAG_FETCHED_SUCCESS";
export const TAG_FETCHED_FAILED = "TAG_FETCHED_FAILED";

// =====================================================================
// TICKET
// =====================================================================

export const CREATE_TICKET = "CREATE_TICKET";
export const UPDATE_TICKET = "UPDATE_TICKET";
export const UNLOAD_TICKET = "UNLOAD_TICKET";

export const FETCH_TICKET_LIST = "FETCH_TICKET_LIST";
export const TICKET_FETCHED_SUCCESS = "TICKET_FETCHED_SUCCESS";
export const TICKET_FETCHED_FAILED = "TICKET_FETCHED_FAILED";

export const FETCH_COMMENTS = "FETCH_COMMENTS";
export const COMMENTS_FETCH_SUCCESS = "COMMENTS_FETCH_SUCCESS";
export const COMMENTS_FETCH_FAILED = "COMMENTSFETCH_FAILED";

export const FETCH_EMAIL = "FETCH_EMAIL";
export const EMAIL_FETCH_SUCCESS = "EMAIL_FETCH_SUCCESS";
export const EMAIL_FETCH_FAILED = "EMAIL_FETCH_FAILED";
export const SEND_EMAIL = "SEND_EMAIL";
export const CLEAR_EMAIL = "CLEAR_EMAIL";
export const ESCALATE_TICKET = "ESCALATE_TICKET";

export const ADD_COMMENT = "ADD_COMMENT";

// =====================================================================
// REMOTE CONFIG
// =====================================================================

export const ADD_REMOTE_CONFIG = "ADD_REMOTE_CONFIG";
export const UPDATE_REMOTE_CONFIG = "UPDATE_REMOTE_CONFIG";
export const UNLOAD_REMOTE_CONFIG = "UNLOAD_REMOTE_CONFIG";

export const FETCH_REMOTE_CONFIG_LIST = "FETCH_REMOTE_CONFIG_LIST";
export const REMOTE_CONFIG_FETCHED_SUCCESS = "REMOTE_CONFIG_FETCHED_SUCCESS";
export const REMOTE_CONFIG_FETCHED_FAILED = "REMOTE_CONFIG_FETCHED_FAILED";

export const PUSHED_REMOTE_CONFIG = "PUSHED_REMOTE_CONFIG";

// =====================================================================
// CUSTOM CATEGORY
// =====================================================================

export const ADD_CUSTOM_CATEGORY = "ADD_CUSTOM_CATEGORY";
export const UPDATE_CUSTOM_CATEGORY = "UPDATE_CUSTOM_CATEGORY";
export const UNLOAD_CUSTOM_CATEGORY = "UNLOAD_CUSTOM_CATEGORY";

export const FETCH_CUSTOM_CATEGORY_LIST = "FETCH_CUSTOM_CATEGORY_LIST";
export const CUSTOM_CATEGORY_FETCHED_SUCCESS =
  "CUSTOM_CATEGORY_FETCHED_SUCCESS";
export const CUSTOM_CATEGORY_FETCHED_FAILED = "CUSTOM_CATEGORY_FETCHED_FAILED";

export const PUSHED_CUSTOM_CATEGORY = "PUSHED_CUSTOM_CATEGORY";

// =====================================================================
// ARTICLES
// =====================================================================

export const CREATE_ARTICLE = "CREATE_ARTICLE";
export const UPDATE_ARTICLE = "UPDATE_ARTICLE";
export const UNLOAD_ARTICLE = "UNLOAD_ARTICLE";

export const FETCH_ARTICLE_LIST = "FETCH_ARTICLE_LIST";
export const ARTICLE_FETCH_SUCCESS = "ARTICLE_FETCH_SUCCESS";
export const ARTICLE_FETCH_FAILED = "ARTICLE_FETCH_FAILED";

export const ARTICLE_TOGGLE_SUCCESS = "ARTICLE_TOGGLE_SUCCESS";

// =====================================================================
// ARTICLE CATEGORY
// =====================================================================

export const CREATE_ARTICLE_CATEGORY = "CREATE_ARTICLE_CATEGORY";
export const UPDATE_ARTICLE_CATEGORY = "UPDATE_ARTICLE_CATEGORY";
export const UNLOAD_ARTICLE_CATEGORY = "UNLOAD_ARTICLE_CATEGORY";

export const FETCH_ARTICLE_CATEGORY_LIST = "FETCH_ARTICLE_CATEGORY_LIST";
export const ARTICLE_CATEGORY_FETCH_SUCCESS = "ARTICLE_CATEGORY_FETCH_SUCCESS";
export const ARTICLE_CATEGORY_FETCH_FAILED = "ARTICLE_CATEGORY_FETCH_FAILED";

export const ARTICLE_CATEGORY_TOGGLE_SUCCESS =
  "ARTICLE_CATEGORY_TOGGLE_SUCCESS";

// =====================================================================
// ARTICLE PARTNER
// =====================================================================

export const CREATE_ARTICLE_PARTNER = "CREATE_ARTICLE_PARTNER";
export const UPDATE_ARTICLE_PARTNER = "UPDATE_ARTICLE_PARTNER";
export const UNLOAD_ARTICLE_PARTNER = "UNLOAD_ARTICLE_PARTNER";
export const GET_TOKEN = "GET_TOKEN";

export const FETCH_ARTICLE_PARTNER_LIST = "FETCH_ARTICLE_PARTNER_LIST";
export const ARTICLE_PARTNER_FETCH_SUCCESS = "ARTICLE_PARTNER_FETCH_SUCCESS";
export const ARTICLE_PARTNER_FETCH_FAILED = "ARTICLE_PARTNER_FETCH_FAILED";

export const ARTICLE_PARTNER_TOGGLE_SUCCESS = "ARTICLE_PARTNER_TOGGLE_SUCCESS";

// =====================================================================
// SUBSCRIPTION
// =====================================================================

export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const UNLOAD_SUBSCRIPTION = "UNLOAD_SUBSCRIPTION";

export const FETCH_SUBSCRIPTION_LIST = "FETCH_SUBSCRIPTION_LIST";
export const SUBSCRIPTION_FETCH_SUCCESS = "SUBSCRIPTION_FETCH_SUCCESS";
export const SUBSCRIPTION_FETCH_FAILED = "SUBSCRIPTION_FETCH_FAILED";

export const SUBSCRIPTION_TOGGLE_SUCCESS = "SUBSCRIPTION_TOGGLE_SUCCESS";

// =====================================================================
// PRIME OFFERS
// =====================================================================

export const CREATE_PRIME_OFFER = "CREATE_PRIME_OFFER";
export const UPDATE_PRIME_OFFER = "UPDATE_PRIME_OFFER";
export const RESET_PRIME_OFFER = "RESET_PRIME_OFFER";
export const UNLOAD_PRIME_OFFER = "UNLOAD_PRIME_OFFER";

export const PUSHED_PRIME_OFFER = "PUSHED_PRIME_OFFER";

export const FETCH_PRIME_OFFER_LIST = "FETCH_PRIME_OFFER_LIST";
export const PRIME_OFFER_FETCHED_SUCCESS = "PRIME_OFFER_FETCHED_SUCCESS";
export const PRIME_OFFER_FETCHED_FAILED = "PRIME_OFFER_FETCHED_FAILED";

// export const UPDATE_ACTION_POOL = 'UPDATE_ACTION_POOL';

// =====================================================================
// BANNER
// =====================================================================

export const CREATE_BANNER = "CREATE_BANNER";
export const UPDATE_BANNER = "UPDATE_BANNER";
export const FETCH_BANNER_LIST = "FETCH_BANNER_LIST";
export const BANNER_FETCHED_SUCCESS = "BANNER_FETCHED_SUCCESS";
export const BANNER_FETCHED_FAILED = "BANNER_FETCHED_FAILED";

// =====================================================================
// REFERRAL CODE CHANGE
// =====================================================================

export const UPDATE_REFERRAL_CODE = 'UPDATE_REFERRAL_CODE';

// =====================================================================
// TEMPLATE
// =====================================================================

export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE";
export const FETCH_TEMPLATE_LIST = "FETCH_TEMPLATE_LIST";
export const TEMPLATE_FETCHED_SUCCESS = "TEMPLATE_FETCHED_SUCCESS";
export const TEMPLATE_FETCHED_FAILED = "TEMPLATE_FETCHED_FAILED";

// =====================================================================
// PROMOTAIONAL MAILER
// =====================================================================

export const CREATE_PROMOTIONAL_MAILER = "CREATE_PROMOTIONAL_MAILER";
export const UPDATE_PROMOTIONAL_MAILER = "UPDATE_PROMOTIONAL_MAILER";
export const PROMOTIONAL_MAILER_FETCHED_FAILED =
  "PROMOTIONAL_MAILER_FETCHED_FAILED";
export const PROMOTIONAL_MAILER_FETCHED_SUCCESS =
  "PROMOTIONAL_MAILER_FETCHED_SUCCESS";
export const FETCH_PROMOTIONAL_MAILER_LIST = "FETCH_PROMOTIONAL_MAILER_LIST";
export const EMAIL_TOGGLE_STATUS = "EMAIL_TOGGLE_STATUS";
export const EMAIL_TOGGLE_SUCCESS = "EMAIL_TOGGLE_SUCCESS";
export const EMAIL_TOGGLE_FAILED = "EMAIL_TOGGLE_FAILED";
export const EMAIL_ACTIVE_TOGGLE_STATUS = "EMAIL_ACTIVE_TOGGLE_STATUS";
export const EMAIL_BLOCK_TOGGLE_STATUS = "EMAIL_BLOCK_TOGGLE_STATUS";

// =====================================================================
// SENT EMAIL
// =====================================================================

export const EMAIL_SENT_STATUS = "EMAIL_SENT_STATUS";
export const EMAIL_SENT_SUCCESS = "EMAIL_SENT_SUCCESS";
export const EMAIL_SENT_FAILED = "EMAIL_SENT_FAILED";

// =====================================================================
// CALL BACK
// =====================================================================

export const FETCH_AFFISE_CALLBACK_LIST = "FETCH_AFFISE_CALLBACK_LIST";
export const AFFISE_CALLBACK_FETCHED_SUCCESS =
  "AFFISE_CALLBACK_FETCHED_SUCCESS";
export const AFFISE_CALLBACK_FETCHED_FAILED = "AFFISE_CALLBACK_FETCHED_FAILED";

export const FETCH_VIDEO_CALLBACK_LIST = "FETCH_VIDEO_CALLBACK_LIST";
export const VIDEO_CALLBACK_FETCHED_SUCCESS = "VIDEO_CALLBACK_FETCHED_SUCCESS";
export const VIDEO_CALLBACK_FETCHED_FAILED = "VIDEO_CALLBACK_FETCHED_FAILED";

export const FETCH_NEWS_CALLBACK_LIST = "FETCH_NEWS_CALLBACK_LIST";
export const NEWS_CALLBACK_FETCHED_SUCCESS = "NEWS_CALLBACK_FETCHED_SUCCESS";
export const NEWS_CALLBACK_FETCHED_FAILED = "NEWS_CALLBACK_FETCHED_FAILED";

// =====================================================================
// Entertainment Tile
// =====================================================================

export const CREATE_ENTERTAINMENT_TILE = "CREATE_ENTERTAINMENT_TILE";
export const UPDATE_ENTERTAINMENT_TILE = "UPDATE_ENTERTAINMENT_TILE";
export const FETCH_ENTERTAINMENT_TILE_LIST = "FETCH_ENTERTAINMENT_TILE_LIST";
export const ENTERTAINMENT_TILE_FETCHED_SUCCESS =
  "ENTERTAINMENT_TILE_FETCHED_SUCCESS";
export const ENTERTAINMENT_TILE_FETCHED_FAILED =
  "ENTERTAINMENT_TILE_FETCHED_FAILED";

// =====================================================================
// Entertainment Banner
// =====================================================================

export const CREATE_CUSTOM_BANNER = "CREATE_CUSTOM_BANNER";
export const UPDATE_CUSTOM_BANNER = "UPDATE_CUSTOM_BANNER";
export const FETCH_CUSTOM_BANNER_LIST = "FETCH_CUSTOM_BANNER_LIST";
export const CUSTOM_BANNER_FETCHED_SUCCESS = "CUSTOM_BANNER_FETCHED_SUCCESS";
export const CUSTOM_BANNER_FETCHED_FAILED = "CUSTOM_BANNER_FETCHED_FAILED";

// =====================================================================
// ALPHA BUILDS
// =====================================================================

export const UPDATE_ALPHA_BUILD = "UPDATE_ALPHA_BUILD";
export const ALPHA_BUILD_STATUS_CHECK = "ALPHA_BUILD_STATUS_CHECK";
export const ALPHA_BUILD_STATUS_CHECK_SUCCESS = "ALPHA_BUILD_STATUS_CHECK_SUCCESS";
export const ALPHA_BUILD_RESET_STATUS = "ALPHA_BUILD_RESET_STATUS";
export const FETCH_ALPHA_BUILDS_LIST = "FETCH_ALPHA_BUILDS_LIST";
export const ALPHA_BUILDS_FETCHED_SUCCESS = "ALPHA_BUILDS_FETCHED_SUCCESS";
export const ALPHA_BUILDS_FETCHED_FAILED = "ALPHA_BUILDS_FETCHED_FAILED";


// =====================================================================
// DAILY OFFERS
// =====================================================================

export const CREATE_DAILY_OFFER = "CREATE_DAILY_OFFER";
export const UPDATE_DAILY_OFFER = "UPDATE_DAILY_OFFER";
export const RESET_DAILY_OFFER = "RESET_DAILY_OFFER";
export const UPDATE_DAILY_OFFER_DATA = "UPDATE_DAILY_OFFER_DATA";

export const PUSHED_DAILY_OFFER = "PUSHED_DAILY_OFFER";

export const UNLOAD_DAILY_OFFER = "UNLOAD_DAILY_OFFER";

export const FETCH_DAILY_OFFER_LIST = "FETCH_DAILY_OFFER_LIST";
export const DAILY_OFFER_FETCHED_SUCCESS = "DAILY_OFFER_FETCHED_SUCCESS";
export const DAILY_OFFER_FETCHED_FAILED = "DAILY_OFFER_FETCHED_FAILED";



// USER REPORT
// =====================================================================

export const FETCH_USER_REPORTS_LIST = 'FETCH_USER_REPORTS_LIST';
export const USER_REPORT_FETCHED_SUCCESS = 'USER_REPORT_FETCHED_SUCCESS';
export const USER_REPORT_FETCHED_FAILED = 'USER_REPORT_FETCHED_FAILED';


// =====================================================================
// ADVERT OFFERS
// =====================================================================

export const CREATE_ADVERT_OFFER = "CREATE_ADVERT_OFFER";
export const UPDATE_ADVERT_OFFER = "UPDATE_ADVERT_OFFER";
export const RESET_ADVERT_OFFER = "RESET_ADVERT_OFFER";
export const UPDATE_ADVERT_OFFER_DATA = "UPDATE_ADVERT_OFFER_DATA";

export const PUSHED_ADVERT_OFFER = "PUSHED_ADVERT_OFFER";

export const UNLOAD_ADVERT_OFFER = "UNLOAD_ADVERT_OFFER";

export const FETCH_ADVERT_OFFER_LIST = "FETCH_ADVERT_OFFER_LIST";
export const ADVERT_OFFER_FETCHED_SUCCESS = "ADVERT_OFFER_FETCHED_SUCCESS";
export const ADVERT_OFFER_FETCHED_FAILED = "ADVERT_OFFER_FETCHED_FAILED";
// UPLOAD POSTBACK
// =====================================================================

export const FETCH_UPLOAD_POSTBACK_LIST = 'FETCH_UPLOAD_POSTBACK_LIST';
export const UPLOAD_POSTBACK_FETCHED_SUCCESS = 'UPLOAD_POSTBACK_FETCHED_SUCCESS';
export const UPLOAD_POSTBACK_FETCHED_FAILED = 'UPLOAD_POSTBACK_FETCHED_FAILED';

// =====================================================================
// SHARE OFFER CALLBACKS
// =====================================================================

export const CREATE_SHARE_OFFER = 'CREATE_SHARE_OFFER';
export const UPDATE_SHARE_OFFER = 'UPDATE_SHARE_OFFER';
export const RESET_SHARE_OFFER = 'RESET_SHARE_OFFER';
export const UNLOAD_SHARE_OFFER = 'UNLOAD_SHARE_OFFER';
export const PUSHED_SHARE_OFFER = 'PUSHED_SHARE_OFFER';
export const FETCH_SHARE_OFFER_LIST = 'FETCH_SHARE_OFFER_LIST';
export const SHARE_OFFER_FETCHED_SUCCESS = 'SHARE_OFFER_FETCHED_SUCCESS';
export const SHARE_OFFER_FETCHED_FAILED = 'SHARE_OFFER_FETCHED_FAILED';

import { applyMiddleware, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { promiseMiddleware, localStorageMiddleware } from './middleware';
import createRootReducer from './reducers';

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  const middlewares = [thunkMiddleware, promiseMiddleware, localStorageMiddleware];
  const middlewareEnhancer = applyMiddleware(
    routerMiddleware(history), // for dispatching history actions
    ...middlewares,
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composedEnhancers,
  );

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(createRootReducer(history)));
  }

  return store;
}

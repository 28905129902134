import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const EditButton = ({ record }) => (
  <Button
    type={
      record.active
      || record.status === 'ACTIVE'
      || record.status === 'RESOLVED'
        ? 'primary'
        : 'dashed'
    }
    size="small"
    icon={<EditOutlined />}
    title="View/Edit"
    // disabled={record.campaign_status === 'SENT' ? true : false}
    // disabled={!permission.view}
  />
);

EditButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
});

export default connect(mapStateToProps)(EditButton);

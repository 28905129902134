import {
  CREATE_TICKET,
  UPDATE_TICKET,
  UNLOAD_TICKET,
  TICKET_FETCHED_SUCCESS,
  COMMENTS_FETCH_SUCCESS,
  ADD_COMMENT,
  EMAIL_FETCH_SUCCESS,
  SEND_EMAIL,
  CLEAR_EMAIL,
} from '../constants/actionTypes';

const initialState = {
  tickets: [],
  comments: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
  email: {
    subject: '',
    conversations: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TICKET:
    case UPDATE_TICKET:
      return {
        ...state,
        message: action.payload.msg,
      };

    case UNLOAD_TICKET:
      return initialState;

    case TICKET_FETCHED_SUCCESS:
      return {
        ...state,
        tickets: action.payload.tickets.map((item) => {
          // eslint-disable-next-line no-underscore-dangle
          const newItem = { ...item, id: item.id };
          return newItem;
        }),
        pager: action.payload.pager,
      };

    case COMMENTS_FETCH_SUCCESS:
      return {
        ...state,
        comments: action.payload.comments,
      };

    case ADD_COMMENT:
      return {
        ...state,
        comments: [...state.comments, action.payload.comment],
      };

    case EMAIL_FETCH_SUCCESS:
      return {
        ...state,
        email: action.payload,
      };
    case SEND_EMAIL: {
      const emailState = { ...state.email };
      if (action.payload.status < 400) {
        emailState.conversations.push(action.payload);
      }
      const updatedEmailState = { ...emailState, subject: action.subject };
      return {
        ...state,
        email: updatedEmailState,
      };
    }

    case CLEAR_EMAIL:
      return {
        ...state,
        email: {
          subject: '',
          conversations: [],
        },
      };

    default:
      return state;
  }
};

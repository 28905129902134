import {
  ADD_ROLE,
  UNLOAD_ROLE,
  UPDATE_ROLE,
  ROLE_FETCHED_SUCCESS,
  ROLE_TOGGLE_FAILED,
  ROLE_TOGGLE_SUCCESS,
  ATTACH_USER_TO_ROLE,
} from '../constants/actionTypes';

const initialState = {
  roles: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
  user: {
    message: '',
  },
};


export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_ROLE:
    case UPDATE_ROLE:
      return {
        ...state,
        message: action.payload.msg,
      };

    case ROLE_FETCHED_SUCCESS:
      return {
        ...state,
        roles: action.payload.roles,
        pager: action.payload.pager,
      };

    case ROLE_TOGGLE_FAILED:
      return state;

    case ROLE_TOGGLE_SUCCESS:
      return {
        ...state,
        roles: state.roles.map((role) => {
          if (role.id !== action.payload.id) return role;
          const newRole = { ...role };
          newRole.active = action.payload.active;
          return newRole;
        }),
      };

    case ATTACH_USER_TO_ROLE:
      return {
        ...state,
        user: {
          ...state.user,
          errors: action.payload.errors,
          message: action.payload.msg,
        },
      };

    case UNLOAD_ROLE:
      return initialState;

    default:
      return state;
  }
};

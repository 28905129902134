import {
  CREATE_ENTERTAINMENT_TILE,
  UPDATE_ENTERTAINMENT_TILE,
  ENTERTAINMENT_TILE_FETCHED_SUCCESS,
} from "../constants/actionTypes";

const initialState = {
  entertainmentTiles: [],
  message: "",
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ENTERTAINMENT_TILE:
    case UPDATE_ENTERTAINMENT_TILE:
      return {
        ...state,
        errors: action.payload.errors,
        message: action.payload.msg,
      };
    case ENTERTAINMENT_TILE_FETCHED_SUCCESS:
      return {
        ...state,
        entertainmentTiles: action.payload.entertainmentTiles.map((item) => {
          // eslint-disable-next-line no-underscore-dangle
          const newItem = { ...item, id: item.id };
          return newItem;
        }),
        pager: action.payload.pager,
      };

    default:
      return state;
  }
};

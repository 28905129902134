import {
    UPDATE_REFERRAL_CODE,
  } from '../constants/actionTypes';

  const initialState = {
    offers: [],
    actionPool: [],
    message: '',
    pager: {
      current: 1,
      pageSize: 15,
      total: 0,
    },
  };


  export default (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_REFERRAL_CODE:
        return {
          ...state,
          message: action.payload.msg,
        };

      default:
        return state;
    }
  };

import {
  ADD_POLICY,
  UPDATE_POLICY,
  POLICY_FETCHED_SUCCESS,
  UNLOAD_POLICY,
  POLICY_TOGGLE_SUCCESS,
  POLICY_TOGGLE_FAILED,
  ATTACH_ROLE_TO_POLICY,
} from '../constants/actionTypes';

const initialState = {
  policies: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
  role: {
    message: '',
  },
};


export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_POLICY:
    case UPDATE_POLICY:
      return {
        ...state,
        message: action.payload.msg,
      };

    case UNLOAD_POLICY:
      return initialState;

    case POLICY_FETCHED_SUCCESS:
      return {
        ...state,
        policies: action.payload.policies,
        pager: action.payload.pager,
      };

    case POLICY_TOGGLE_FAILED:
      return state;

    case POLICY_TOGGLE_SUCCESS:
      return {
        ...state,
        policies: state.policies.map((policy) => {
          if (policy.id !== action.payload.id) return policy;
          const newPolicy = { ...policy };
          newPolicy.active = action.payload.active;
          return newPolicy;
        }),
      };

    case ATTACH_ROLE_TO_POLICY:
      return {
        ...state,
        role: {
          ...state.role,
          message: action.payload.msg,
        },
      };

    default:
      return state;
  }
};

import {
  CREATE_STORE,
  UPDATE_STORE,
  UNLOAD_STORE,
  FETCH_STORE_LIST,
  STORE_LIST_FETCHED_SUCCESS,
  STORE_LIST_FETCHED_FAILED,
  RESET_STORE,
  API_OFFER_SUCCESS,
  API_OFFER_FAILED,
  FETCH_ADMITAD_API_OFFER_LIST,
  FETCH_VCOMM_API_OFFER_LIST,
  FETCH_STORE,
  STORE_FETCHING_SUCCESS,
  STORE_FETCHING_FAILED,
  UPDATE_STORE_DATA,
} from '../constants/actionTypes';
import agent from '../../agent';


export function addStore(values) {
  return { type: CREATE_STORE, payload: agent.Store.create(values) };
}

export function updateStore(id) {
  return (values) => ({
    type: UPDATE_STORE,
    payload: agent.Store.update(id)(values),
  });
}

export function unLoadStore() {
  return { type: UNLOAD_STORE };
}

export function resetStore() {
  return { type: RESET_STORE };
}

// can be used to update partial data of store
export function updateStoreData(storeData) {
  return { type: UPDATE_STORE_DATA, payload: { storeData } };
}

export function fetchStore(id) {
  return { type: FETCH_STORE, payload: agent.Store.get(id) };
}

export function fetchStoreSuccess(store) {
  return { type: STORE_FETCHING_SUCCESS, payload: { store } };
}

export function fetchStoreFailed() {
  return { type: STORE_FETCHING_FAILED };
}

export function loadStores(params) {
  return { type: FETCH_STORE_LIST, payload: agent.Store.list(params) };
}

export function storesLoadedSuccesfully(stores, pager) {
  return { type: STORE_LIST_FETCHED_SUCCESS, payload: { stores, pager } };
}

export function storesLoadingFailed() {
  return { type: STORE_LIST_FETCHED_FAILED };
}

export function loadAdmitAdApisOffer(params) {
  return {
    type: FETCH_ADMITAD_API_OFFER_LIST,
    payload: agent.Admitad.getStores(params),
    source: 'ADMITAD',
  };
}

export function loadVcommApisOffer(params) {
  return {
    type: FETCH_VCOMM_API_OFFER_LIST,
    payload: agent.Vcommission.getOffers(params),
    source: 'VCOMMISSION',
  };
}

export function apiLoadedSuccesfully(apis, source) {
  return { type: API_OFFER_SUCCESS, payload: { apis }, source };
}

export function apiLoadingFailed(source) {
  return { type: API_OFFER_FAILED, source };
}

import axios from "axios";
import qs from "qs";
import { pickBy, identity } from "loadsh";

// const API_AUTH_ROOT = process.env.REACT_APP_AUTH_API_ROOT;
const API_CMS_ROOT = process.env.REACT_APP_CMS_API_ROOT;
const NEWS_PANEL_ROOT = process.env.REACT_APP_NEWS_PANEL_API_ROOT;
const API_CMS_ROOT_DUMMY = process.env.REACT_APP_CMS_API_ROOT_DUMMY;

const AJAX_SEARCH_LIMIT = 10;

let token = null;

const config = (options = {}) => {
  const { type = '' } = options;
  const timeOut = type === 'video' ? 300 * 1000 : 20 * 1000;
  return {
    headers: {
      Authorization: `Bearer ${options.token || token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      ...options.headers,
    },
    timeout: timeOut,
    params: options.params,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
    ...options,
  };
}

const responseBody = (res) => res.data;

const makeFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });
  return formData;
};

const authRequest = {
  delete: (url) =>
    axios.delete(`${API_CMS_ROOT}${url}`, config()).then(responseBody),
  get: (url, options) =>
    axios.get(`${API_CMS_ROOT}${url}`, config(options)).then(responseBody),
  put: (url, body) =>
    axios.put(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
  post: (url, body) =>
    axios.post(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
  patch: (url, body) =>
    axios.patch(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
};

const cmsRequest = {
  delete: (url) =>
    axios.delete(`${API_CMS_ROOT}${url}`, config()).then(responseBody),
  get: (url, options) =>
    axios.get(`${API_CMS_ROOT}${url}`, config(options)).then(responseBody),
  put: (url, body) =>
    axios.put(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
  post: (url, body) =>
    axios.post(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
  patch: (url, body) =>
    axios.patch(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
};

const articleRequest = {
  delete: (url) =>
    axios.delete(`${NEWS_PANEL_ROOT}${url}`, config()).then(responseBody),
  get: (url, options) =>
    axios.get(`${NEWS_PANEL_ROOT}${url}`, config(options)).then(responseBody),
  dummyGet: (url, options) =>
    axios
      .get(`${API_CMS_ROOT_DUMMY}${url}`, config(options))
      .then(responseBody),
  put: (url, body) =>
    axios.put(`${NEWS_PANEL_ROOT}${url}`, body, config()).then(responseBody),
  post: (url, body) =>
    axios.post(`${NEWS_PANEL_ROOT}${url}`, body, config()).then(responseBody),
  patch: (url, body) =>
    axios.patch(`${NEWS_PANEL_ROOT}${url}`, body, config()).then(responseBody),
  dummyPatch: (url, body) =>
    axios
      .patch(`${API_CMS_ROOT_DUMMY}${url}`, body, config())
      .then(responseBody),
};


const uploadRequest = (file, resource, type, is_image_type, block_type, postback_type, options) => {
  let postUrl = `${API_CMS_ROOT}/common/upload`;
  let metadata = {file, resource, type, is_image_type}
  if (type === 'user_report')
  {
      postUrl = `${API_CMS_ROOT}/misc/block-users?block_type=${block_type}`
      metadata = {file}
  }

  if (type === 'upload_postback')
  {
      postUrl = `${API_CMS_ROOT}/callback/upload_postback?postback_type=${postback_type}`
      metadata = {file}
  }

  if (type === 'video') {
    options.type = 'video';
    postUrl = `${API_CMS_ROOT}/common/v2/upload`
    metadata = {file}
  }

  return axios.post(
    postUrl,
    makeFormData(metadata),
    config({ 'Content-Type': 'multipart/form-data', ...options }),
  )
  .then(responseBody);
}

const Auth = {
  currentUser: () => authRequest.get("/auth/current-user"),
  login: (email, password) =>
    authRequest.post("/auth/login", { email_id: email, password }),
  register: (username, email, password) =>
    authRequest.post("/auth/register", { username, email, password }),
  refreshToken: (_token) =>
    authRequest.get("/auth/refresh-token", { token: _token }),
};

const Resource = {
  get: (id) => authRequest.get(`/resources/${id}`),
  list: (params) => authRequest.get("/resources", { params }),
  create: (payload) => authRequest.post("/resources", { ...payload }),
  patch: (id, active) => authRequest.patch(`/resources/${id}`, { active }),
  update: (id) => (payload) =>
    authRequest.put(`/resources/${id}`, { ...payload }),
  search: (query) =>
    authRequest.get("/resources/search", {
      params: { query, limit: AJAX_SEARCH_LIMIT },
    }),
  getResponsibleUser: (resource, action) =>
    authRequest.get("/resources/valid-user", { params: { resource, action } }),
};

const Policy = {
  get: (id) => authRequest.get(`/policies/${id}`),
  list: (params) => authRequest.get("/policies", { params }),
  create: (payload) => authRequest.post("/policies", { ...payload }),
  patch: (id, active) => authRequest.patch(`/policies/${id}`, { active }),
  update: (id) => (payload) =>
    authRequest.put(`/policies/${id}`, { ...payload }),
  search: (query) =>
    authRequest.get("/policies/search", {
      params: { query, limit: AJAX_SEARCH_LIMIT },
    }),
  attachRoles: (id) => (payload) =>
    authRequest.put(`/policies/${id}/roles`, { roles: payload.roles }),
};

const Role = {
  get: (id) => authRequest.get(`/roles/${id}`),
  list: (params) => authRequest.get("/roles", { params }),
  create: (payload) => authRequest.post("/roles", { ...payload }),
  patch: (id, active) => authRequest.patch(`/roles/${id}`, { active }),
  update: (id) => (payload) => authRequest.put(`/roles/${id}`, { ...payload }),
  search: (query) =>
    authRequest.get("/roles/search", {
      params: { query, limit: AJAX_SEARCH_LIMIT },
    }),
  attachUsers: (id) => (payload) =>
    authRequest.put(`/roles/${id}/users`, { users: payload.users }),
};

const User = {
  get: (id) => authRequest.get(`/auth/${id}`),
  list: (params) => authRequest.get("/auth", { params }),
  create: (payload) => authRequest.post("/auth/create_user", { ...payload }),
  patch: (id, active) => authRequest.put(`/auth/${id}`, { active }),
  update: (id) => (payload) => authRequest.put(`/auth/${id}`, { ...payload }),
  search: (query) =>
    authRequest.get("/users/search", {
      params: { query, limit: AJAX_SEARCH_LIMIT },
    }),
  attachRoles: (id) => (payload) =>
    authRequest.put(`/users/${id}/roles`, { roles: payload.roles }),
  changePassword: (id) => (payload) =>
    authRequest.post(`/users/${id}/change-password`, { ...payload }),
};

const SmsService = {
  get: (id) => cmsRequest.get(`/sms/v1/provider/${id}`),
  list: (params) => cmsRequest.get("/sms/v1/provider", { params }),
  create: (payload) => cmsRequest.post("/sms/v1/provider", { ...payload }),
  update: (id) => (payload) =>
    cmsRequest.put(`/sms/v1/provider/${id}`, { ...payload }),
};

const SendSms = {
  create: (payload) => cmsRequest.post("/sms/test-sms", { ...payload }),
};

const Store = {
  get: (id) => cmsRequest.get(`/store/${id}`),
  list: (params) => cmsRequest.get("/store", { params }),
  create: (payload) => cmsRequest.post("/store", { ...payload }),
  getCategories: (id) => cmsRequest.get(`/store/${id}/categories`),
  update: (id) => (payload) => cmsRequest.put(`/store/${id}`, { ...payload }),
  // updateMapping: (id) => (payload) => cmsRequest.patch(`/store/${id}`, { ...payload }),
};

const Category = {
  get: (id) => cmsRequest.get(`/category/${id}`),
  list: (params) => cmsRequest.get("/category", { params }),
  create: (payload) => cmsRequest.post("/category", { ...payload }),
  update: (id) => (payload) =>
    cmsRequest.put(`/category/${id}`, { ...payload }),
};

const Banner = {
  get: (id) => cmsRequest.get(`/offer/banner/${id}`),
  create: (payload) => cmsRequest.post("/offer/banner", { ...payload }),
  list: (params) => cmsRequest.get("/offer/banner", { params }),
  update: (id) => (payload) =>
    cmsRequest.put(`/offer/banner/${id}`, { ...payload }),
};

const AlphaBuilds = {
    get: (id) => cmsRequest.get(`/callback/v1/apk/${id}`),
    list: (params) => cmsRequest.get("/callback/v1/apk", { params }),
    update: (id) => (payload) =>
    cmsRequest.put(`/callback/v1/apk/${id}`, { ...payload }),
};

const Entertainment = {
  get: (id) => cmsRequest.get(`/entertainment/${id}`),
  create: (payload) => cmsRequest.post("/entertainment", { ...payload }),
  list: (params) => cmsRequest.get("/entertainment", { params }),
  update: (id) => (payload) =>
    cmsRequest.put(`/entertainment/${id}`, { ...payload }),
};

const referralCodeRequest = {
  delete: (url) => axios.delete(`${API_CMS_ROOT}${url}`, config()).then(responseBody),
  get: (url, options) => axios.get(`${API_CMS_ROOT}${url}`, config(options)).then(responseBody),
  put: (url, body) => axios.put(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
  post: (url, body) => axios.post(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
  patch: (url, body) => axios.patch(`${API_CMS_ROOT}${url}`, body, config()).then(responseBody),
};

const CustomBanner = {
  get: (id) => cmsRequest.get(`/entertainment/custom_banner/${id}`),
  create: (payload) =>
    cmsRequest.post("/entertainment/custom_banner", { ...payload }),
  list: (params) => cmsRequest.get("/entertainment/custom_banner", { params }),
  update: (id) => (payload) =>
    cmsRequest.put(`/entertainment/custom_banner/${id}`, { ...payload }),
};

const Template = {
  get: (id) => cmsRequest.get(`/email/template/${id}`),
  create: (payload) => cmsRequest.post("/email/template", { ...payload }),
  list: (params) => cmsRequest.get("/email/template", { params }),
  update: (id) => (payload) =>
    cmsRequest.put(`/email/template/${id}`, { ...payload }),
};

const PromotionalMailer = {
  get: (id) => cmsRequest.get(`/email/${id}`),
  create: (payload) => cmsRequest.post("/email", { ...payload }),
  list: (params) => cmsRequest.get("/email", { params }),
  update: (id) => (payload) => cmsRequest.put(`/email/${id}`, { ...payload }),
  activeTag: (id) =>
    cmsRequest.post("/email/block-campaign", { email_campaign_id: id }),
  blockTag: (id) =>
    cmsRequest.post("/email/resume-campaign", { email_campaign_id: id }),
};

const DeliveryServer = {
  get: () => cmsRequest.get("/email/server-config"),
};

const TestEmail = {
  send: (payload) => cmsRequest.post("/email/send-test-mail", { ...payload }),
};

const UserOfferCountFilter = {
  get: () => cmsRequest.get("/email/user-offer-count-filter"),
};

const GetUserCount = {
  get: (payload) => cmsRequest.post("/email/get-user-count", { ...payload }),
};

const StatsDashBoard = {
  sms: (params) => cmsRequest.get("/stats/sms", { params }),
  nps: (params) => cmsRequest.get("/stats/nps-rating", { params }),
  userOffers: (params) => cmsRequest.get("/stats/user-offers", { params }),
  wallet: (params) => cmsRequest.get("/stats/wallet", { params }),
  referalCount: (params) => cmsRequest.get("/stats/referral-count", { params }),
  userInstallTimeSeries: (params) =>
    cmsRequest.get("/stats/user-install-time-series", { params }),
  npsCount: (params) => cmsRequest.get("/stats/nps-count", { params }),
  npsRating: (params) => cmsRequest.get("/stats/nps-rating", { params }),
  installSource: (params) =>
    cmsRequest.get("/stats/install-source", { params }),
  transactionCount: (params) =>
    cmsRequest.get("/stats/transaction-count", { params }),
  transactionRevenue: (params) =>
    cmsRequest.get("/stats/transaction-revenue", { params }),
};

const SendEmail = {
  send: (id) => cmsRequest.post("/email/send", { email_campaign_id: id }),
};

const SubCategory = {
  get: (id) => cmsRequest.get(`/category/sub_category/${id}`),
  list: (params) => cmsRequest.get("/category/sub_category", { params }),
  create: (payload) =>
    cmsRequest.post("/category/sub_category", { ...payload }),
  update: (id) => (payload) =>
    cmsRequest.put(`/category/sub_category/${id}`, { ...payload }),
};

const Tag = {
  get: (id) => cmsRequest.get(`/category/tag/${id}`),
  list: (params) => cmsRequest.get("/category/tag", { params }),
  create: (payload) => cmsRequest.post("/category/tag", { ...payload }),
  update: (id) => (payload) =>
    cmsRequest.put(`/category/tag/${id}`, { ...payload }),
};

const Offer = {
  get: (id) => cmsRequest.get(`/offer/${id}`),
  list: (params) => cmsRequest.get("/offer", { params }),
  create: (payload) => cmsRequest.post("/offer", { ...payload }),
  update: (id) => (payload) => cmsRequest.put(`/offer/${id}`, { ...payload }),
};

const Admitad = {
  getStores: (params) => cmsRequest.get("/admitad/search_stores", { params }),
  connectStore: (payload) =>
    cmsRequest.patch("/admitad/connect", { ...payload }),
};

const Vcommission = {
  getOffers: (params) => cmsRequest.get("/vcomm/search_offer", { params }),
  connectStore: (payload) => cmsRequest.patch("/vcomm/connect", { ...payload }),
};

const Ticket = {
  get: (id) => cmsRequest.get(`/tickets/${id}`),
  getComments: (id) => cmsRequest.get(`/tickets/${id}/comment`),
  addComment: (id, comment) =>
    cmsRequest.patch(`/tickets/${id}/comment`, { comment }),
  list: (params) => cmsRequest.get("/tickets", { params }),
  create: (payload) => cmsRequest.post("/tickets", { ...payload }),
  update: (id) => (payload) =>
    cmsRequest.patch(`/tickets/${id}`, { ...payload }),
  getEmail: (id) =>
    cmsRequest.get(`/tickets/${id}/conversation`, { timeout: 200 * 1000 }),
  sendEmail: (id, email, subject) =>
    cmsRequest.patch(`/tickets/${id}/conversation`, {
      mail_content: email,
      subject,
    }),
  escalateTicket: (id) => (payload) =>
    cmsRequest.patch(`/tickets/${id}/escalation`, { ...payload }),
};

const makeSearchParams = (params) => ({
  params: {
    limit: AJAX_SEARCH_LIMIT,
    ...pickBy(params, identity),
  },
});

const Search = {
  resource: (query, extra = {}) =>
    authRequest.get("/resources/search", makeSearchParams({ query, ...extra })),
  policy: (query, extra = {}) =>
    authRequest.get("/policies/search", makeSearchParams({ query, ...extra })),
  role: (query, extra = {}) =>
    authRequest.get("/roles/search", makeSearchParams({ query, ...extra })),
  user: (query, extra = {}) =>
    authRequest.get("/users/search", makeSearchParams({ query, ...extra })),

  country: (query, extra = {}) =>
    cmsRequest.get(
      "/common/search_country",
      makeSearchParams({ query, ...extra })
    ),
  primeOffers: (query, extra = {}) =>
    cmsRequest.get(
      "/prime_offers/search",
      makeSearchParams({ query, ...extra })
    ),
  state: (query, extra = {}) =>
    cmsRequest.get(
      "/common/search_state",
      makeSearchParams({ query, ...extra })
    ),
  city: (query, extra = {}) =>
    cmsRequest.get(
      "/common/search_city",
      makeSearchParams({ query, ...extra })
    ),
  network: (query, extra = {}) =>
    cmsRequest.get("/search_network", makeSearchParams({ query, ...extra })),
  device: (query, extra = {}) =>
    cmsRequest.get("/search_device", makeSearchParams({ query, ...extra })),

  store: (query, extra = {}) =>
    cmsRequest.get("/store/search", makeSearchParams({ query, ...extra })),
  advertiser: (query, extra = {}) =>
    cmsRequest.get(
      "/advertisers/search",
      makeSearchParams({ query, ...extra })
    ),
  category: (query, extra = {}) =>
    cmsRequest.get("/category/search", makeSearchParams({ query, ...extra })),
  subcategory: (query, extra = {}) =>
    cmsRequest.get(
      "/category/sub_category/search",
      makeSearchParams({ query, ...extra })
    ),
  tag: (query, extra = {}) =>
    cmsRequest.get(
      "/category/tag/search",
      makeSearchParams({ query, ...extra })
    ),
  userId: (query, extra = {}) =>
    cmsRequest.get(
      "/tickets/search_by_user",
      makeSearchParams({ query, ...extra })
    ),
  subject: (query, extra = {}) =>
    cmsRequest.get(
      "/tickets/search_by_subject",
      makeSearchParams({ query, ...extra })
    ),
  articleCategory: (query, extra = {}) =>
    articleRequest.get(
      "/categories/search",
      makeSearchParams({ query, ...extra })
    ),
  articleSubscription: (query, extra = {}) =>
    articleRequest.get(
      "/partner/subscription/search",
      makeSearchParams({ query, ...extra })
    ),
  article: (query) =>
    articleRequest.get("/article/search", {
      params: { query, limit: AJAX_SEARCH_LIMIT },
    }),
  sms: (query) =>
    cmsRequest.get("/sms/search", {
      params: { query, limit: AJAX_SEARCH_LIMIT },
    }),
  offer: (query, extra = {}) =>
    cmsRequest.get("/offer/search", makeSearchParams({ query, ...extra })),
  banner: (query) =>
    cmsRequest.get("/offer/search/banner", {
      params: { query, limit: AJAX_SEARCH_LIMIT },
    }),
  template: (query) =>
    cmsRequest.get("/email/template/search", {
      params: { query, limit: AJAX_SEARCH_LIMIT },
    }),
  entertainment: (query) =>
    cmsRequest.get("/entertainment/search", {
      params: { query, limit: AJAX_SEARCH_LIMIT },
    }),
  dailyOffer: (query, extra = {}) =>
    cmsRequest.get("/daily_offer/search", makeSearchParams({ query, ...extra })),
  advertOffer: (query, extra = {}) =>
    cmsRequest.get("/daily_offer/advert_offer/search", makeSearchParams({ query, ...extra })),
  bannerScript: (query, extra = {}) => cmsRequest.get(
    '/common/search_banner_script',
    makeSearchParams({ query, ...extra }),
  ),
  bannerLanguage: (query, extra = {}) => cmsRequest.get(
    '/common/search_banner_language',
    makeSearchParams({ query, ...extra }),
  ),
  offerCategory: (query, extra = {}) => cmsRequest.get(
    '/share_offer/offer_category',
    makeSearchParams({ query, ...extra }),
  ),
};

const RemoteConfig = {
  get: (id) => cmsRequest.get(`/misc/remote_config/${id}`),
  create: (payload) => cmsRequest.post("/misc/remote_config", { ...payload }),
  list: (params) => cmsRequest.get("/misc/remote_config", { params }),
  update: (id) => (payload) =>
    cmsRequest.put(`/misc/remote_config/${id}`, { ...payload }),
};

const CustomCategory = {
  get: (id) => cmsRequest.get(`/category/custom-category/${id}`),
  create: (payload) =>
    cmsRequest.post("/category/custom-category", { ...payload }),
  list: (params) => cmsRequest.get("/category/custom-category", { params }),
  update: (id) => (payload) =>
    cmsRequest.put(`/category/custom-category/${id}`, { ...payload }),
};

const Push = {
  resource: (id, resource) =>
    cmsRequest.post("/push_resource", { id, resource }),
};

const Article = {
  get: (id) => articleRequest.get(`/article/${id}`),
  list: (params) => articleRequest.get("/article", { params }),
  create: (payload) => articleRequest.post("/article", { ...payload }),
  update: (id) => (payload) =>
    articleRequest.put(`/article/${id}`, { ...payload }),
  search: (query) =>
    articleRequest.get("/article/search", {
      params: { query, limit: AJAX_SEARCH_LIMIT },
    }),
};

const articleCategory = {
  get: (id) => articleRequest.get(`/categories/${id}`),
  list: (params) => articleRequest.get("/categories", { params }),
  create: (payload) => articleRequest.post("/categories", { ...payload }),
  update: (id) => (payload) =>
    articleRequest.put(`/categories/${id}`, { ...payload }),
};

const articlePartner = {
  get: (id) => cmsRequest.get(`/partner/${id}`),
  getToken: () => cmsRequest.get("/misc/generate-key"),
  list: (params) => cmsRequest.get("/partner", { params }),
  create: (payload) => cmsRequest.post("/partner", { ...payload }),
  update: (id) => (payload) => cmsRequest.put(`/partner/${id}`, { ...payload }),
};

const subscription = {
  list: (params) => articleRequest.get("/partner/subscription", { params }),
  create: (payload) =>
    articleRequest.post("/partner/subscription", { ...payload }),
  update: (id) => (payload) =>
    articleRequest.put(`/partner/subscription/${id}`, { ...payload }),
  get: (id) => articleRequest.get(`/partner/subscription/${id}`),
};

const primeOffers = {
  get: (id) => cmsRequest.get(`/prime_offers/${id}`),
  list: (params) => cmsRequest.get("/prime_offers", { params }),
  create: (payload) => cmsRequest.post("/prime_offers", { ...payload }),
  update: (id) => (payload) =>
    cmsRequest.put(`/prime_offers/${id}`, { ...payload }),
};

const DailyOffer = {
  get: (id) => cmsRequest.get(`/daily_offer/${id}`),
  list: (params) => cmsRequest.get("/daily_offer", { params }),
  create: (payload) => cmsRequest.post("/daily_offer", { ...payload }),
  update: (id) => (payload) =>
    cmsRequest.put(`/daily_offer/${id}`, { ...payload }),
};

const ReferralCode = {
  update: (payload) => referralCodeRequest.put('/refer', { ...payload }),
};

const AdvertOffer = {
  get: (id) => cmsRequest.get(`/daily_offer/advert_offer/${id}`),
  list: (params) => cmsRequest.get("/daily_offer/advert_offer", { params }),
  create: (payload) => cmsRequest.post("/daily_offer/advert_offer", { ...payload }),
  update: (id) => (payload) =>
    cmsRequest.put(`/daily_offer/advert_offer/${id}`, { ...payload }),
}

const AffiseCallback = {
  list: (params) => cmsRequest.get("/callback/affise", { params }),
  get: (id) => cmsRequest.get(`/callback/affise/${id}`),
};

const VideoCallback = {
  list: (params) => cmsRequest.get("/callback/video", { params }),
  get: (id) => cmsRequest.get(`/callback/video/${id}`),
};

const NewsCallback = {
  list: (params) => cmsRequest.get("/callback/news", { params }),
  get: (id) => cmsRequest.get(`/callback/news/${id}`),
};

const UserReport ={
  list: (params) => cmsRequest.get("/misc/block-users", { params })
}

const UploadPostback ={
  list: (params) => cmsRequest.get("/callback/upload_postback", { params })
}

const ShareOffers = {
  create: (payload) => cmsRequest.post('/share_offer', { ...payload }),
  get: (id) => cmsRequest.get(`/share_offer/${id}`),
  list: (params) => cmsRequest.get('/share_offer', { params }),
  update: (id) => (payload) => cmsRequest.put(`/share_offer/${id}`, { ...payload }),
};

export default {
  Auth,
  Resource,
  Policy,
  Role,
  User,
  SmsService,
  Store,
  Category,
  Banner,
  Entertainment,
  CustomBanner,
  PromotionalMailer,
  Template,
  DeliveryServer,
  TestEmail,
  UserOfferCountFilter,
  GetUserCount,
  SendEmail,
  StatsDashBoard,
  SubCategory,
  Offer,
  Search,
  Admitad,
  SendSms,
  Vcommission,
  Tag,
  Ticket,
  Push,
  RemoteConfig,
  CustomCategory,
  setToken: (_token) => {
    token = _token;
  },
  getToken: () => token,
  upload: uploadRequest,
  uploadUrl: `${API_CMS_ROOT}/common/upload`,
  Article,
  articleCategory,
  articlePartner,
  subscription,
  PrimeOffers: primeOffers,
  AffiseCallback,
  VideoCallback,
  NewsCallback,
  AlphaBuilds,
  DailyOffer,
  UserReport,
  AdvertOffer,
  ReferralCode,
  UploadPostback,
  ShareOffers
};

/* eslint-disable import/prefer-default-export */

export const optionMapper = (item) => ({ value: item, text: item });

export const statusOptions = ["ACTIVE", "PENDING", "DISABLED"].map(
  optionMapper
);

export const offerLinkSourceOptions = [
  { value: "affise", text: "Affise" },
  { value: "sapphyre", text: "Sapphyre" },
];

export const callbackListingOptions = [
  "COMPLETED",
  "UNAUTHORIZED",
  "PENDING",
  "EXCEPTION",
  "PROCESSING",
  "PRE_CONDITION_FAILED",
].map(optionMapper);

export const offerStatusOptions = [
  "ACTIVE",
  "PENDING",
  "PAUSED",
  "DISABLED",
].map(optionMapper);
export const primeOfferStatusOptions = ["ACTIVE", "PENDING", "DISABLED"].map(
  optionMapper
);
export const smsTextOptions = [
  { value: "NET_CORE", text: "NET CORE" },
  { value: "VALUE_FIRST", text: "VALUE FIRST" },
];
export const genderOptions = [
  { value: "male", text: "MALE" },
  { value: "female", text: "FEMALE" },
  { value: "others", text: "OTHERS" },
];

export const ctaTextOptions = [
  { value: "INSTALL_NOW", text: "INSTALL NOW" },
  { value: "REGISTER_TODAY", text: "REGISTER TODAY" },
  { value: "TRY_NOW", text: "TRY NOW" },
];

export const ScheduleTypeOptions = [
  { value: "NORMAL", text: "NORMAL" },
  { value: "DAILY", text: "DAILY" },
];

export const redirectionFlagOptions = [
  { value: "REFER_AND_EARN", text: "REFER AND EARN" },
  { value: "OFFER_DETAILS", text: "OFFER DETAILS" },
  { value: "SHARE_AND_EARN", text: "SHARE AND EARN" },
];

export const isActiveOption = [
  { value: true, text: "ACTIVE" },
  { value: false, text: "DISABLED" },
];

export const isHiddenOption = [
  { value: true, text: "ACTIVE" },
  { value: false, text: "DISABLED" },
];

export const isActiveOptionsForEmail = [
  "ACTIVE",
  "DISABLED",
  "SENT",
  "BLOCKED",
].map(optionMapper);

export const schedulingTypeOption = ["NORMAL", "DAILY"].map(optionMapper);

export const smsStatusOptions = ["ACTIVE", "DISABLED"].map(optionMapper);
export const remoteConfigInputTypeOptions = [
  "NUMBER",
  "STRING",
  "BOOLEAN",
  "JSON",
].map(optionMapper);

export const categoryTypeOptions = ["PARENT", "CUSTOM"].map(optionMapper);

export const goalTitleOptions = [
  { value: "Sale", text: "Sale" },
  { value: "Leads", text: "Leads" },
  { value: "Signup", text: "Signup" },
  { value: "Signin", text: "Signin" },
  { value: "Register", text: "Register" },
  { value: "Application", text: "Application" },
  { value: "D0Sale", text: "D0Sale" },
  { value: "D7Sale", text: "D7Sale" },
  { value: "Install", text: "Install" },
  { value: "Shopper", text: "Shopper" },
  { value: "Listing", text: "Listing" },
  { value: "Load_Money", text: "Load Money" },
  { value: "Journey_Completion", text: "Journey Completion" },
  { value: "Transaction", text: "Transaction" },
  { value: "Loan_Approval", text: "Loan Approval" },
  { value: "Report_Received", text: "Report Received" },
  { value: "Genuine_Install", text: "Genuine Install" },
  { value: "Form_submission", text: "Form submission" },
];

export const payoutTypeOptions = ["FLAT", "PERCENTAGE"].map(optionMapper);

export const currencyOptions = ["INR", "USD"].map(optionMapper);

export const offerTypeOptionsOffer = ["CPI", "CPAT", "CPR", "CPL", "CPG"].map(
  optionMapper
);

export const offerTypeOptions = ["CPI", "CPR", "CPAT", "CPL", "CPG"].map(optionMapper);

export const dailyOfferTypeOptions = ["SDK", "CCT"].map(optionMapper);

export const bannerTypeOptions = ["CCT", "DEEPLINK", "OFFER", "ATTRIBUTION"].map(optionMapper);
export const customBannerTypeOptions = [
  "CCT",
  "DEEPLINK",
  "SDK",
  "WEBVIEW",
].map(optionMapper);

export const entertainmentRedirectionFlagOptions = [
  { value: 41, text: "Frizza Game" },
  { value: 42, text: "Frizza Quizz" },
  { value: 5, text: "Refer & Earn" },
];

export const primeOfferTypeOptions = ["CPL", "CPAT", "CPI"].map(optionMapper);

export const trafficSourceOptions = ["EMAIL", "SMS", "MOBILE_APP"].map(
  optionMapper
);

export const clickParamsOptions = [
  "appname",
  "gaid",
  "idfa",
  "pid_subpid",
  "subparam1",
  "subparam2",
  "subparam3",
  "subparam4",
  "subparam5",
  "subparam6",
  "subparam7",
  "subparam8",
  "subpid",
];

export const ticketSourceOptions = ["CMS", "APP"].map(optionMapper);
export const ticketTypeOptions = ["STORE", "OFFER", "CATEGORY"].map(
  optionMapper
);
export const allTicketTypeOptions = ["STORE", "OFFER", "CATEGORY", "APP"].map(
  optionMapper
);

export const ticketPriorityOptions = ["HIGH", "MEDIUM", "LOW"].map(
  optionMapper
);

export const ticketStatusOptions = [
  "PENDING",
  "HOLD",
  "RESOLVE",
  "REJECT",
  "CLOSE",
].map(optionMapper);
export const reporterTicketOptions = ["PENDING", "HOLD", "CLOSE"].map(
  optionMapper
);

export const pushTypeOptions = [
  "STORE",
  "OFFER",
  "CATEGORY",
  "SUB_CATEGORY",
  "TAG",
  "REMOTE_CONFIG",
  "CUSTOM_CATEGORY",
];

export const userOfferTypeOptions = ["APP", "WEBSITE"].map(optionMapper);

export const offerSourceOptions = [
  "ADMITAD",
  "VCOMMISSION",
  "MANUAL",
  "FLIPKART",
].map(optionMapper);

export const templateStatusOptions = ["ACTIVE", "DISABLED"].map(optionMapper);

export const sendSmsServiceOptions = [
  { value: "NET_CORE", text: "NET CORE" },
  { value: "VALUE_FIRST", text: "VALUE FIRST" },
];

export const statsFilterOptions = [
  { value: 1, text: "Today" },
  { value: 7, text: "Last 7 Days" },
  { value: 14, text: "Last 14 Days" },
  { value: 30, text: "Last Months" },
  { value: 0, text: "Custom Filter" },
];


export const userReportOptions = [
  { value: true, text: "COMPLETED" },
  { value: false, text: "IN_PROGRESS" },
];

export const userBlockTypeOptions = ["EMAIL_ID", "USER_ID", "IP_ADDRESS", "BENEFICIARY_ID"].map(
  optionMapper
);


export const uploadPostbackTypeOptions = ["OFFER", "SPECIAL_OFFER"].map(
  optionMapper
);


export const advertOfferNameOptions = ["AD_GATE", "AD_GEM", "AYET_STUDIOS", "LOOTABLY", "OFFER_TORO", "HANG_MY_HANDS", "REVU"].map(
  optionMapper
);

import {
  CREATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  SUBSCRIPTION_FETCH_SUCCESS,
  SUBSCRIPTION_TOGGLE_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  subscription: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SUBSCRIPTION:
    case UPDATE_SUBSCRIPTION:
      return {
        ...state,
        message: action.payload.msg,
      };

    case SUBSCRIPTION_FETCH_SUCCESS:
      return {
        ...state,
        subscription: action.payload.subscription,
        pager: action.payload.pager,
      };

    case SUBSCRIPTION_TOGGLE_SUCCESS:
      return {
        ...state,
        subscription: state.subscription.map((sub) => {
          if (sub.id !== action.payload.id) return sub;
          const newSubscription = { ...sub };
          newSubscription.is_active = action.payload.active;
          return newSubscription;
        }),
      };

    default:
      return state;
  }
};

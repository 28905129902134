import {
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  UNLOAD_CATEGORY,
  CATEGORY_FETCHED_SUCCESS,
  SUB_CATEGORY_FETCHED_SUCCESS,
  PUSHED_CATEGORY,
  PUSHED_SUB_CATEGORY,
} from '../constants/actionTypes';

const initialState = {
  categories: [],
  subCategories: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};


export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CATEGORY:
    case UPDATE_CATEGORY:
      return {
        ...state,
        errors: action.payload.errors,
        message: action.payload.msg,
      };

    case UNLOAD_CATEGORY:
      return initialState;

    case PUSHED_CATEGORY:
      return {
        ...state,
        categories: state.categories.map((item) => {
          if (item.id === action.payload.id) {
            const newItem = { ...item };
            // if (!newItem.modified_at) newItem.modified_at = action.payload.date;
            newItem.pushed_at = action.payload.date;
            return newItem;
          }
          return item;
        }),
      };

    case PUSHED_SUB_CATEGORY:
      return {
        ...state,
        subCategories: state.subCategories.map((item) => {
          if (item.id === action.payload.id) {
            const newItem = { ...item };
            // if (!newItem.modified_at) newItem.modified_at = action.payload.date;
            newItem.pushed_at = action.payload.date;
            return newItem;
          }
          return item;
        }),
      };

    case CATEGORY_FETCHED_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories.map((item) => {
          // eslint-disable-next-line no-underscore-dangle
          const newItem = { ...item, id: item.id };
          return newItem;
        }),
        pager: action.payload.pager,
      };

    case SUB_CATEGORY_FETCHED_SUCCESS:
      return {
        ...state,
        subCategories: action.payload.subCategories.map((item) => {
          // eslint-disable-next-line no-underscore-dangle
          const newItem = { ...item, id: item.id };
          return newItem;
        }),
        pager: action.payload.pager,
      };

    default:
      return state;
  }
};

import React, { PureComponent, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Spin } from 'antd';

import routes from './routes';
import agent from './agent';
import storage from './utils/storage';
import {
  logout, loggedIn, forceLogout,
} from './store/actions/authActions';
import { appLoaded, updateCurrentUser } from './store/actions/commonActions';

import { showErrorMessage } from './utils/messages';

import './App.less';

const Login = React.lazy(() => import('./views/Login'));
const Dashboard = React.lazy(() => import('./views/Dashboard'));

class App extends PureComponent {
  componentDidMount() {
    const {
      appReady, goToLogin, loggedInUser, logoutUser,
    } = this.props;
    const { token, expiry, issuedAt } = storage.getAccesTokenInfo();
    const isTokenExpired = storage.isTokenExpired(expiry, issuedAt);

    if (!token) {
      // there is no token means user is not logged in
      goToLogin();
      appReady();
    } else if (isTokenExpired) {
      // there is a token but it is expired, need to get a new token
      agent.Auth.refreshToken(storage.getRefreshTokenInfo().token)
        .then((payload) => {
          storage.setAccesToken(payload.data.access_token, payload.data.exp);
          agent.setToken(payload.data.access_token);
          loggedInUser();
          appReady();
        })
        .catch((err) => {
          showErrorMessage(err);
          logoutUser();
          appReady();
          throw err;
        });
    } else {
      // valid token
      agent.setToken(token);
      loggedInUser();
      appReady();
    }
  }

  render() {
    // eslint-disable-next-line no-shadow
    const {
      // eslint-disable-next-line no-shadow
      loggedIn, appLoaded, logoutUser, appReady, updateUser,
    } = this.props;

    if (!appLoaded) {
      return (
        <div style={{
          textAlign: 'center',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        >
          <Spin tip="App inializing..." />
        </div>
      );
    }

    return (
      <Switch>
        {
          loggedIn ? (
            <Route
              path="/"
              component={() => (
                <Suspense fallback={<Spin />}>
                  <Dashboard
                    logoutUser={logoutUser}
                    appReady={appReady}
                    updateUser={updateUser}
                    routes={routes}
                  />
                </Suspense>
              )}
            />
          ) : null
        }

        <Route
          path="/"
          component={() => (
            <Suspense fallback={<Spin />}>
              <Login />
            </Suspense>
          )}
        />
        {/* <Redirect to="/" /> */}
      </Switch>
    );
  }
}

App.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  appLoaded: PropTypes.bool.isRequired,
  appReady: PropTypes.func.isRequired,
  goToLogin: PropTypes.func.isRequired,
  loggedInUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  appLoaded: state.common.appLoaded,
  loggedIn: state.auth.loggedIn,
  ...ownProps,
});

const mapDispatchToProps = (dispatch) => ({
  appReady: (user) => dispatch(appLoaded(user)),
  updateUser: (user) => dispatch(updateCurrentUser(user)),
  goToLogin: () => dispatch(logout()),
  loggedInUser: () => dispatch(loggedIn()),
  logoutUser: () => dispatch(forceLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

import { UPLOAD_POSTBACK_FETCHED_SUCCESS } from '../constants/actionTypes';

const initialState = {
  uploadPostback: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
  role: {
    message: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_POSTBACK_FETCHED_SUCCESS:
      return {
        ...state,
        uploadPostback: action.payload.uploadPostback,
        pager: action.payload.pager,
      };

    default:
      return state;
  }
};

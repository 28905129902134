import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { nameColumn, withTag } from '../utils/columns';
import { ActiveColor } from './listing';

import { loadAdmitAdApisOffer, loadVcommApisOffer } from '../store/actions/storeActions';
import { FETCH_ADMITAD_API_OFFER_LIST, FETCH_VCOMM_API_OFFER_LIST } from '../store/constants/actionTypes';

const TarriffLeaf = lazy(() => import('../components/admitad/TarriffLeaf'));
const TarriffGoalLeaf = lazy(() => import('../components/vcommission/TarriffGoalLeaf'));
const TarriffPayoutGroup = lazy(() => import('../components/vcommission/TariffPayoutGroup'));
const TarriffPayoutGroupLeaf = lazy(() => import('../components/vcommission/TariffPayoutGroupLeaf'));

const Network = {
  ADMITAD: {
    name: 'ADMITAD',
    responseMapper: (item) => ({
      id: item.id,
      name: item.name,
      ecpc: item.ecpc,
      cr: item.cr,
      avg_hold_time: item.avg_hold_time,
      status: item.status,
      description: item.description,
      // adspace_id: item.adspace_id,
      // adspace_name: item.adspace_ref && item.adspace_ref.name,
    }),
    action: loadAdmitAdApisOffer,
    actionType: FETCH_ADMITAD_API_OFFER_LIST,
    columns: [
      nameColumn('Name', 'name', true, { sorter: (a, b) => (a.name > b.name) }),
      // nameColumn('Created At', 'created_at'),
      nameColumn('ECPC', 'ecpc', true, { sorter: (a, b) => (a.ecpc - b.ecpc), width: 100 }),
      nameColumn('CR', 'cr', true, { sorter: (a, b) => (a.cr - b.cr), width: 100 }),
      // withTooltip('Adspace', 'adspace_name', 'adspace_id'),
      withTag('Status', 'status', { color: ActiveColor, width: 80 }),
    ],
    generateTarrifData: (data) => {
      const treeData = data.action_details.map((action) => {
        const newAction = { ...action };
        newAction.key = `action_${action.id}`;
        newAction.title = `action - ${action.name} - [${action.type}]`;
        newAction.checkable = true;
        newAction.id = action.id;

        newAction.children = action.tariffs.map((tariff) => {
          const newTariff = { ...tariff };
          newTariff.key = `tariff_${tariff.id}`;
          newTariff.title = `tariff - ${tariff.name}`;
          newTariff.checkable = false;

          newTariff.children = tariff.rates.map((rate) => {
            const newRate = {};
            newRate.key = `rate_${rate.id}`;
            // eslint-disable-next-line react/jsx-filename-extension
            newRate.title = <Suspense><TarriffLeaf rate={rate} /></Suspense>;
            newRate.checkable = false;
            return newRate;
          });
          return newTariff;
        });
        return newAction;
      });

      return treeData;
    },
  },
  VCOMMISSION: {
    name: 'VCOMMISSION',
    responseMapper: (item) => (item),
    action: loadVcommApisOffer,
    actionType: FETCH_VCOMM_API_OFFER_LIST,
    columns: [
      nameColumn('Name', 'name', true, { sorter: (a, b) => (a.name > b.name) }),
      nameColumn('Default Payout', 'default_payout', true, { sorter: (a, b) => (a.ecpc - b.ecpc), width: 140 }),
      nameColumn('Percent Payout', 'percent_payout', true, { sorter: (a, b) => (a.cr - b.cr), width: 140 }),
      nameColumn('Payout Type', 'payout_type', false),
      withTag('Status', 'status', { color: ActiveColor, width: 80 }),
    ],
    generateTarrifData: (data) => {
      const treeData = [];
      const goals = data.goals || [];
      const payoutGroups = data.payout_groups || [];

      if (goals.length) {
        treeData.push({
          key: 'goals_0',
          title: 'Goals',
          checkable: false,
          id: 'g0',
          children: goals.map((goal) => {
            const newGoal = {};
            newGoal.key = `action_${goal.id}`;
            newGoal.id = goal.id;
            // eslint-disable-next-line react/jsx-filename-extension
            newGoal.title = (
              <Suspense fallback={<Spin />}>
                <TarriffGoalLeaf goal={goal} />
              </Suspense>
            );
            newGoal.checkable = true;
            return newGoal;
          }),
        });
      }

      if (payoutGroups.length) {
        treeData.push({
          key: 'payout_group_0',
          title: 'Payout Groups',
          checkable: true,
          id: 'pg0',
          children: payoutGroups.map((pg) => {
            const newPayoutGroup = {};
            newPayoutGroup.key = `action_${pg.cashflow_group.id}`;
            newPayoutGroup.id = pg.cashflow_group.id;
            // eslint-disable-next-line react/jsx-filename-extension
            newPayoutGroup.title = (
              <Suspense fallback={<Spin />}>
                <TarriffPayoutGroup pg={pg} />
              </Suspense>
            );
            newPayoutGroup.checkable = true;

            newPayoutGroup.children = pg.cashflow_group.rules.map((rule) => {
              const newRule = {};
              newRule.key = `rule_${rule.id}`;
              newRule.checkable = false;
              newRule.isLeaf = true;
              newRule.title = (
                <Suspense fallback={<Spin />}>
                  <TarriffPayoutGroupLeaf rule={rule} />
                </Suspense>
              );
              return newRule;
            });

            return newPayoutGroup;
          }),
        });
      }
      return treeData;
    },
  },
};

export default Network;

import { combineReducers } from "redux";

import { connectRouter } from "connected-react-router";

import auth from "./auth";
import common from "./common";
import resource from "./resource";
import policy from "./policy";
import role from "./role";
import user from "./user";
import smsService from "./smsService";
import store from "./store";
import offer from "./offer";
import category from "./category";
import tag from "./tag";
import ticket from "./ticket";
import remoteConfig from "./remoteConfig";
import customCategory from "./customCategory";
import article from "./article";
import articleCategory from "./articleCategory";
import articlePartner from "./articlePartner";
import subscription from "./subscription";
import PrimeOffer from "./primeOffer";
import banner from "./banner";
import template from "./template";
import promotionalMailer from "./promotionalMailer";
import affiseCallback from "./affiseCallback";
import videoCallback from "./videoCallback";
import newsCallback from "./newsCallback";
import entertainmentTile from "./entertainmentTile";
import customBanner from "./customBanner";
import alphaBuilds from "./alphaBuilds";
import dailyOffer from "./dailyOffer";
import userReport from './userReport';
import advertOffer from "./advertOffer";
import uploadPostback from './uploadPostback';
import referralCode from './refer';
import ShareOffer from './shareOffer';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    tag,
    auth,
    role,
    user,
    store,
    offer,
    common,
    policy,
    ticket,
    resource,
    category,
    smsService,
    remoteConfig,
    customCategory,
    article,
    articleCategory,
    articlePartner,
    subscription,
    PrimeOffer,
    banner,
    template,
    promotionalMailer,
    affiseCallback,
    videoCallback,
    newsCallback,
    entertainmentTile,
    customBanner,
    alphaBuilds,
    dailyOffer,
    userReport,
    advertOffer,
    uploadPostback,
    referralCode,
    ShareOffer,
  });

export default createRootReducer;

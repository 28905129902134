import React, { PureComponent } from 'react';
import { Popconfirm ,Switch} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';

class PopConfirm extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      active: this.props.status
    }
  }

  pushToApp = () => {
    const { id, record, tagBlock } = this.props;
    const { active } = this.state;
    record.campaign_status = active ? "ACTIVE" : "BLOCKED";
    this.setState({active: !active});
    tagBlock(id,!active);
  }

  render() {
    const { active } = this.state;
    const { record } = this.props;
    return (
      <Popconfirm
        title="Are you sure to block this campaign？"
        icon={<QuestionCircleOutlined  />}
        placement="right"
        okText="Yes"
        cancelText="no"
        onConfirm={this.pushToApp}
        disabled={record.campaign_status === 'SENT' ? true : false}
      >
         <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={active}
            size="small"
            disabled={record.campaign_status === 'SENT' ? true : false}
        />
      </Popconfirm>
    );
  }
}

export default PopConfirm;

import  {
    CREATE_ADVERT_OFFER,
    UPDATE_ADVERT_OFFER,
    ADVERT_OFFER_FETCHED_SUCCESS
} from '../constants/actionTypes';

const initialState = {
    advertOffers: [],
    message: '',
    pager: {
        current: 1,
        pageSize: 15,
        total: 0,
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_ADVERT_OFFER:
        case UPDATE_ADVERT_OFFER:
            return {
                ...state,
                errors: action.payload.errors,
                message: action.payload.msg
            }
        case ADVERT_OFFER_FETCHED_SUCCESS:
            return {
                ...state,
                advertOffers: action.payload.advertOffers.map((item) => {
                    // eslint-disable-next-line no-underscore-dangle
                    const newItem = { ...item, id: item.id };
                    return newItem;
                  }),
                pager: action.payload.pager,
              };

        default:
            return state;
    }
}

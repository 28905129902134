import {
    CREATE_SHARE_OFFER,
    UPDATE_SHARE_OFFER,
    UNLOAD_SHARE_OFFER,
    SHARE_OFFER_FETCHED_SUCCESS,
    RESET_SHARE_OFFER,
  } from '../constants/actionTypes';

  const initialState = {
    offers: [],
    message: '',
    pager: {
      current: 1,
      pageSize: 15,
      total: 0,
    },
  };

  export default (state = initialState, action) => {
    switch (action.type) {
      case CREATE_SHARE_OFFER:
      case UPDATE_SHARE_OFFER:
        return {
          ...state,
          message: action.payload.msg,
        };

      case RESET_SHARE_OFFER:
        return {
          ...state,
          message: '',
        };

      case SHARE_OFFER_FETCHED_SUCCESS:
        return {
          ...state,
          offers: action.payload.offers.map((item) => {
            const newItem = { ...item, id: item.id };
            return newItem;
          }),
          pager: action.payload.pager,
        };

      case UNLOAD_SHARE_OFFER:
        return initialState;

      default:
        return state;
    }
  };

/* eslint-disable import/prefer-default-export */
export const StatusColor = {
  ACTIVE: "#2a8ef4",
  PENDING: "purple",
  PAUSED: "geekblue",
  DISABLED: "#f50",
  HOLD: "#2db7f5",
  RESOLVE: "#8BC34A",
  REJECT: "#FF5722",
  CLOSE: "#8BC34A",
  SENT: "#8BC34A",
  BLOCKED: "geekblue",
  COMPLETED: "green",
  UNAUTHORIZED: "#f50",
  EXCEPTION: "#2db7f5",
  PRE_CONDITION_FAILED: "#FF5722",
  PROCESSING: "geekblue",
};

export const CategoryTypeColor = {
  PARENT: "#2db7f5",
  CUSTOM: "#87d068",
};

export const ActiveColor = {
  0: "red",
  1: "blue",
  INACTIVE: "red",
  ACTIVE: "blue",
};

export const PriorityColor = {
  HIGH: "red",
  MEDIUM: "purple",
  LOW: "green",
};

export const NewtorkColor = {
  MANUAL: "purple",
  FLIPKART: "red",
  ADMITAD: "#2db7f5",
  VCOMMISSION: "#8BC34A",
};


export const ReportColor = {
  IN_PROGRESS: "blue",
  COMPLETED: 'green'
}

export const SchedulingTypeColor = {
  DAILY: "#2db7f5",
  NORMAL: "#87d068",
};

import {
  ADD_RESOURCE,
  UNLOAD_RESOURCE,
  UPDATE_RESOURCE,
  RESOURCE_TOGGLE_FAILED,
  RESOURCE_TOGGLE_SUCCESS,
  FETCH_RESOURCE_LIST,
  RESOURCE_FETCHED_SUCCESS,
  RESOURCE_FETCHED_FAILED,
  RESOURCE_DELETE_FAILED,
} from '../constants/actionTypes';

const initialState = {
  resources: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};


export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_RESOURCE:
    case UPDATE_RESOURCE:
      return {
        ...state,
        message: action.payload.msg,
      };

    case RESOURCE_TOGGLE_FAILED:
      return state;

    case RESOURCE_TOGGLE_SUCCESS:
      return {
        ...state,
        resources: state.resources.map((resource) => {
          if (resource.id !== action.payload.id) return resource;
          const newResource = { ...resource };
          newResource.active = action.payload.active;
          return newResource;
        }),
      };

    case UNLOAD_RESOURCE:
      return initialState;

    case RESOURCE_FETCHED_SUCCESS:
      return {
        ...state,
        resources: action.payload.resources,
        pager: action.payload.pager,
      };

    case RESOURCE_FETCHED_FAILED:
    case FETCH_RESOURCE_LIST:
    case RESOURCE_DELETE_FAILED:
      return state;

    default:
      return state;
  }
};

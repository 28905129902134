import {
  CREATE_ARTICLE,
  UPDATE_ARTICLE,
  ARTICLE_FETCH_SUCCESS,
  ARTICLE_TOGGLE_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  articles: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ARTICLE:
    case UPDATE_ARTICLE:
      return {
        ...state,
        message: action.payload.msg,
      };

    case ARTICLE_FETCH_SUCCESS:
      return {
        ...state,
        articles: action.payload.articles,
        pager: action.payload.pager,
      };


    case ARTICLE_TOGGLE_SUCCESS:
      return {
        ...state,
        articles: state.articles.map((article) => {
          if (article.id !== action.payload.id) return article;
          const newArticle = { ...article };
          newArticle.is_active = action.payload.active;
          return newArticle;
        }),
      };

    default:
      return state;
  }
};

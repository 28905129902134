import {
  CREATE_SMS,
  UPDATE_SMS,
  UNLOAD_SMS,
  SMS_FETCHED_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  sms: [],
  errors: {},
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};


export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SMS:
    case UPDATE_SMS:
      return {
        ...state,
        errors: action.payload.errors,
        message: action.payload.msg,
      };

    case UNLOAD_SMS:
      return initialState;

    case SMS_FETCHED_SUCCESS:
      return {
        ...state,
        sms: action.payload.sms.map((item) => {
          // eslint-disable-next-line no-underscore-dangle
          const newItem = { ...item, id: item.id };
          return newItem;
        }),
        pager: action.payload.pager,
      };

    default:
      return state;
  }
};

import { NEWS_CALLBACK_FETCHED_SUCCESS } from "../constants/actionTypes";

const initialState = {
  newsCallbacks: [],
  message: "",
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NEWS_CALLBACK_FETCHED_SUCCESS:
      return {
        ...state,
        newsCallbacks: action.payload.newsCallbacks,
        pager: action.payload.pager,
      };
    default:
      return state;
  }
};

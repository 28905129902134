import  { CREATE_PROMOTIONAL_MAILER,
    UPDATE_PROMOTIONAL_MAILER,
    PROMOTIONAL_MAILER_FETCHED_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
    promotionalMailers: [],
    message: '',
    pager: {
        current: 1,
        pageSize: 15,
        total: 0,
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_PROMOTIONAL_MAILER:
        case UPDATE_PROMOTIONAL_MAILER:
            return {
                ...state,
                errors: action.payload.errors,
                message: action.payload.msg
            }
        case PROMOTIONAL_MAILER_FETCHED_SUCCESS:
            return {
                ...state,
                promotionalMailers: action.payload.promotionalMailers.map((item) => {
                  // eslint-disable-next-line no-underscore-dangle
                  const newItem = { ...item, id: item.id };
                  return newItem;
                }),
                pager: action.payload.pager,
              };
        default:
            return state;
    }
}

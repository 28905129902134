import {
  CREATE_TAG,
  UPDATE_TAG,
  UNLOAD_TAG,
  TAG_FETCHED_SUCCESS,
  PUSHED_TAG,
} from '../constants/actionTypes';

const initialState = {
  tags: [],
  errors: {},
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};


export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TAG:
    case UPDATE_TAG:
      return {
        ...state,
        errors: action.payload.errors,
        message: action.payload.msg,
      };

    case UNLOAD_TAG:
      return initialState;

    case PUSHED_TAG:
      return {
        ...state,
        tags: state.tags.map((item) => {
          if (item.id === action.payload.id) {
            const newItem = { ...item };
            // if (!newItem.modified_at) newItem.modified_at = action.payload.date;
            newItem.pushed_at = action.payload.date;
            return newItem;
          }
          return item;
        }),
      };

    case TAG_FETCHED_SUCCESS:
      return {
        ...state,
        tags: action.payload.tags.map((item) => {
          // eslint-disable-next-line no-underscore-dangle
          const newItem = { ...item, id: item.id };
          return newItem;
        }),
        pager: action.payload.pager,
      };

    default:
      return state;
  }
};

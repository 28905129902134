import  {
    CREATE_DAILY_OFFER,
    UPDATE_DAILY_OFFER,
    DAILY_OFFER_FETCHED_SUCCESS
} from '../constants/actionTypes';

const initialState = {
    dailyOffers: [],
    message: '',
    pager: {
        current: 1,
        pageSize: 15,
        total: 0,
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_DAILY_OFFER:
        case UPDATE_DAILY_OFFER:
            return {
                ...state,
                errors: action.payload.errors,
                message: action.payload.msg
            }
        case DAILY_OFFER_FETCHED_SUCCESS:
            return {
                ...state,
                dailyOffers: action.payload.dailyOffers,
//                .map((item) => {
//                  // eslint-disable-next-line no-underscore-dangle
//                  const newItem = { ...item, id: item.id };
//                  return newItem;
//                }),
                pager: action.payload.pager,
              };

        default:
            return state;
    }
}

import { lazy } from "react";
import {
  PlusOutlined,
  BarsOutlined,
  HomeOutlined,
  SettingOutlined,
} from "@ant-design/icons";

/*
  Format for route
  {
    invisible: {Boolean} if true it won't be shown in sidebar
    path: {String} url to be matched
    exact: {Boolean} for react-router exact
    icon: {Component | Icon}
    sidebar: {String} to be shown on sidebar
    component: {Component} a react component to be rendered if path match
    child: {Array} array of routes for sub-routing,
    checkPermission: {Func} { optional } check to verify current route permission
  }
 */

const Home = lazy(() => import("./views/pages/Home"));
const PageNotFound = lazy(() => import("./views/pages/PageNotFound"));

const User = {
  create: lazy(() => import("./views/pages/user/CreateUser")),
  edit: lazy(() => import("./views/pages/user/CreateUser")),
  list: lazy(() => import("./views/pages/user/ListUsers")),
};

const Store = {
  create: lazy(() => import("./views/pages/store/CreateStore")),
  edit: lazy(() => import("./views/pages/store/CreateStore")),
  list: lazy(() => import("./views/pages/store/ListStores")),
};

const Category = {
  create: lazy(() => import("./views/pages/category/CreateCategory")),
  edit: lazy(() => import("./views/pages/category/CreateCategory")),
  list: lazy(() => import("./views/pages/category/ListCategory")),
};

const Offer = {
  create: lazy(() => import("./views/pages/offer/CreateOffer")),
  edit: lazy(() => import("./views/pages/offer/CreateOffer")),
  list: lazy(() => import("./views/pages/offer/ListOffers")),
};

const DailyOffer = {
  create: lazy(() => import("./views/pages/daily-offer/CreateDailyOffer")),
  edit: lazy(() => import("./views/pages/daily-offer/CreateDailyOffer")),
  list: lazy(() => import("./views/pages/daily-offer/ListDailyOffer")),
};

const AdvertOffer = {
  create: lazy(() => import("./views/pages/advert-offer/CreateAdvertOffer")),
  edit: lazy(() => import("./views/pages/advert-offer/CreateAdvertOffer")),
  list: lazy(() => import("./views/pages/advert-offer/ListAdvertOffer")),
};

const RemoteConfig = {
  create: lazy(() => import("./views/pages/remote-config/CreateRemoteConfig")),
  edit: lazy(() => import("./views/pages/remote-config/CreateRemoteConfig")),
  list: lazy(() => import("./views/pages/remote-config/ListRemoteConfig")),
};

const SubCategory = {
  create: lazy(() => import("./views/pages/category/CreateSubCategory")),
  edit: lazy(() => import("./views/pages/category/CreateSubCategory")),
  list: lazy(() => import("./views/pages/category/ListSubCategory")),
};

const SmsService = {
  create: lazy(() => import("./views/pages/smsService/CreateSms")),
  edit: lazy(() => import("./views/pages/smsService/CreateSms")),
  list: lazy(() => import("./views/pages/smsService/ListSms")),
};

const Banner = {
  create: lazy(() => import("./views/pages/banner/CreateBanner")),
  edit: lazy(() => import("./views/pages/banner/CreateBanner")),
  list: lazy(() => import("./views/pages/banner/ListBanner")),
};

const ReferralCode = {
  create: lazy(() => import('./views/pages/referral-code/CreateReferralCode')),
  edit: lazy(() => import('./views/pages/referral-code/CreateReferralCode')),
};

const Template = {
  create: lazy(() => import("./views/pages/template/CreateTemplate")),
  edit: lazy(() => import("./views/pages/template/CreateTemplate")),
  list: lazy(() => import("./views/pages/template/ListTemplate")),
};

const PromotionalMailer = {
  create: lazy(() =>
    import("./views/pages/promotional-mailer/CreatePromotionalMailer")
  ),
  edit: lazy(() =>
    import("./views/pages/promotional-mailer/CreatePromotionalMailer")
  ),
  list: lazy(() =>
    import("./views/pages/promotional-mailer/ListPromotionalMailer")
  ),
};

const AffiseCallBack = {
  create: lazy(() =>
    import("./views/pages/affise-callback/CreateAffiseCallBack")
  ),
  list: lazy(() => import("./views/pages/affise-callback/ListAffiseCallback")),
  edit: lazy(() =>
    import("./views/pages/affise-callback/CreateAffiseCallBack")
  ),
};

const VideoCallBack = {
  list: lazy(() => import("./views/pages/video-callback/ListVideoCallback")),
  edit: lazy(() => import("./views/pages/video-callback/CreateVideoCallback")),
  create: lazy(() =>
    import("./views/pages/video-callback/CreateVideoCallback")
  ),
};

const NewsCallBack = {
  list: lazy(() => import("./views/pages/news-callback/ListNewsCallback")),
  edit: lazy(() => import("./views/pages/news-callback/CreateNewsCallback")),
  create: lazy(() => import("./views/pages/news-callback/CreateNewsCallback")),
};

const Entertainment = {
  create: lazy(() => import("./views/pages/entertainment/CreateEntertainment")),
  edit: lazy(() => import("./views/pages/entertainment/CreateEntertainment")),
  list: lazy(() => import("./views/pages/entertainment/ListEntertainment")),
};

const CustomBanner = {
  create: lazy(() => import("./views/pages/custom-banner/CreateCustomBanner")),
  edit: lazy(() => import("./views/pages/custom-banner/CreateCustomBanner")),
  list: lazy(() => import("./views/pages/custom-banner/ListCustomBanner")),
};

const AlphaBuilds = {
    edit: lazy(() => import("./views/pages/aplha-builds/EditBuild")),
    list: lazy(() => import("./views/pages/aplha-builds/ListAlphaBuilds")),
  };

const UserReporting = {
  create: lazy(() => import("./views/pages/user-reporting/CreateUserReport")),
  list: lazy(() => import("./views/pages/user-reporting/ListUserReport")),
};

const UploadPostback = {
  create: lazy(() => import("./views/pages/upload-postback/CreateUploadPostback")),
  list: lazy(() => import("./views/pages/upload-postback/ListUploadPostback")),
};

const ShareOffers = {
  create: lazy(() => import('./views/pages/share-offers/CreateShareOffers')),
  edit: lazy(() => import('./views/pages/share-offers/CreateShareOffers')),
  list: lazy(() => import('./views/pages/share-offers/ListShareOffers')),
};

const common = [
  {
    path: "/",
    icon: HomeOutlined,
    exact: true,
    name: "home",
    sidebar: "Dashboard",
    component: Home,
  },
  {
    path: "/404",
    exact: true,
    name: "pageNotFound",
    sidebar: "PageNotFound",
    invisible: true,
    component: PageNotFound,
  },
];

const auth = [
  {
    path: "/users",
    sidebar: "User",
    name: "user",
    child: [
      {
        path: "/create",
        exact: true,
        sidebar: "Create",
        icon: PlusOutlined,
        name: "create",
        component: User.create,
      },
      {
        path: "/:id",
        sidebar: "Edit",
        invisible: true,
        name: "edit",
        component: User.edit,
      },
      {
        path: "",
        exact: true,
        sidebar: "List",
        icon: BarsOutlined,
        name: "list",
        component: User.list,
      },
    ],
  },
];

const cms = [
  {
    path: "/user-report",
    sidebar: "User Report",
    name: "Userreport",
    child: [
    {
        path: "/create",
        exact: true,
        sidebar: "Create",
        icon: PlusOutlined,
        name: "create",
        component: UserReporting.create,
    },
    {
        path: "",
        exact: true,
        sidebar: "List",
        icon: BarsOutlined,
        name: "list",
        component: UserReporting.list,
    },
    ],
  },
  {
    path: "/upload-postback",
    sidebar: "Upload Postback",
    name: "UploadPostbcak",
    child: [
    {
        path: "/create",
        exact: true,
        sidebar: "Create",
        icon: PlusOutlined,
        name: "create",
        component: UploadPostback.create,
    },
    {
        path: "",
        exact: true,
        sidebar: "List",
        icon: BarsOutlined,
        name: "list",
        component: UploadPostback.list,
    },
    ],
  },
  {
    path: "/stores",
    sidebar: "Store",
    name: "store",
    child: [
      {
        path: "/create",
        exact: true,
        sidebar: "Create",
        icon: PlusOutlined,
        name: "create",
        component: Store.create,
      },
      {
        path: "/:id",
        sidebar: "Edit",
        invisible: true,
        name: "edit",
        component: Store.edit,
      },
      {
        path: "",
        exact: true,
        sidebar: "List",
        icon: BarsOutlined,
        name: "list",
        component: Store.list,
      },
    ],
  },
  {
    path: "/categories",
    sidebar: "Category",
    name: "category",
    child: [
      {
        path: "/create",
        exact: true,
        sidebar: "Create",
        icon: PlusOutlined,
        name: "create",
        component: Category.create,
      },
      {
        path: "/:id",
        sidebar: "Edit",
        invisible: true,
        name: "edit",
        component: Category.edit,
      },
      {
        path: "",
        exact: true,
        sidebar: "List",
        icon: BarsOutlined,
        name: "list",
        component: Category.list,
      },
    ],
  },
    {
    path: "/daily-offers",
    sidebar: "DailyOffer",
    name: "dailyOffer",
    child: [
      {
        path: "/create",
        exact: true,
        sidebar: "Create",
        icon: PlusOutlined,
        name: "create",
        component: DailyOffer.create,
      },
      {
        path: "/:id",
        sidebar: "Edit",
        invisible: true,
        name: "edit",
        component: DailyOffer.edit,
      },
      {
        path: "",
        exact: true,
        sidebar: "List",
        icon: BarsOutlined,
        name: "list",
        component: DailyOffer.list,
      },
    ],
  },
  {
    path: "/advert-offers",
    sidebar: "AdvertOffer",
    name: "advertOffer",
    child: [
      // {
      //   path: "/create",
      //   exact: true,
      //   sidebar: "Create",
      //   icon: PlusOutlined,
      //   name: "create",
      //   component: AdvertOffer.create,
      // },
      {
        path: "/:id",
        sidebar: "Edit",
        invisible: true,
        name: "edit",
        component: AdvertOffer.edit,
      },
      {
        path: "",
        exact: true,
        sidebar: "List",
        icon: BarsOutlined,
        name: "list",
        component: AdvertOffer.list,
      },
    ],
  },
  {
    path: "/sub-categories",
    sidebar: "Sub Category",
    name: "subcategory",
    child: [
      {
        path: "/create",
        exact: true,
        sidebar: "Create",
        icon: PlusOutlined,
        name: "create",
        component: SubCategory.create,
      },
      {
        path: "/:id",
        sidebar: "Edit",
        invisible: true,
        name: "edit",
        component: SubCategory.edit,
      },
      {
        path: "",
        exact: true,
        sidebar: "List",
        icon: BarsOutlined,
        name: "list",
        component: SubCategory.list,
      },
    ],
  },
  {
    path: "/offers",
    sidebar: "Offer",
    name: "offer",
    checkPermission: (permission) => permission.Offer,
    child: [
      {
        path: "/create",
        exact: true,
        sidebar: "Create",
        icon: PlusOutlined,
        name: "create",
        component: Offer.create,
      },
      {
        path: "/:id",
        sidebar: "Edit",
        invisible: true,
        name: "edit",
        component: Offer.edit,
      },
      {
        path: "",
        exact: true,
        sidebar: "List",
        icon: BarsOutlined,
        name: "list",
        component: Offer.list,
      },
    ],
  },
  {
    path: "/banner",
    sidebar: "Banner",
    name: "banner",
    child: [
      {
        path: "/create",
        exact: true,
        sidebar: "Create",
        icon: PlusOutlined,
        name: "create",
        component: Banner.create,
      },
      {
        path: "/:id",
        sidebar: "Edit",
        invisible: true,
        name: "edit",
        component: Banner.edit,
      },
      {
        path: "",
        exact: true,
        sidebar: "List",
        icon: BarsOutlined,
        name: "list",
        component: Banner.list,
      },
    ],
  },
  {
    path: '/referral_code',
    sidebar: 'Referral Code',
    name: 'ReferralCode',
    child: [
      {
        path: '/update',
        exact: true,
        sidebar: 'Update',
        icon: PlusOutlined,
        name: 'updateReferralCode',
        component: ReferralCode.create,
      },
    ],
  },
  {
    path: "/alpha-builds",
    sidebar: "Alpha Builds",
    name: "alphaBuilds",
    child: [
      {
        path: "/:id",
        sidebar: "Edit",
        invisible: true,
        name: "edit",
        component: AlphaBuilds.edit,
      },
      {
        path: "",
        exact: true,
        sidebar: "List",
        icon: BarsOutlined,
        name: "list",
        component: AlphaBuilds.list
      },
    ],
  },
  {
    path: "/template",
    sidebar: "Template",
    name: "template",
    child: [
      {
        path: "/create",
        exact: true,
        sidebar: "Create",
        icon: PlusOutlined,
        name: "create",
        component: Template.create,
      },
      {
        path: "/:id",
        sidebar: "Edit",
        invisible: true,
        name: "edit",
        component: Template.edit,
      },
      {
        path: "",
        exact: true,
        sidebar: "List",
        icon: BarsOutlined,
        name: "list",
        component: Template.list,
      },
    ],
  },

  {
    path: "/promotional-mailer",
    sidebar: "Promotional Mailer",
    name: "promotionalmailer",
    child: [
      {
        path: "/create",
        exact: true,
        sidebar: "Create",
        icon: PlusOutlined,
        name: "create",
        component: PromotionalMailer.create,
      },
      {
        path: "/:id",
        sidebar: "Edit",
        invisible: true,
        name: "edit",
        component: PromotionalMailer.edit,
      },
      {
        path: "",
        exact: true,
        sidebar: "List",
        icon: BarsOutlined,
        name: "list",
        component: PromotionalMailer.list,
      },
    ],
  },
  {
    path: "/entertainment",
    sidebar: "Entertainment",
    name: "entertainment",
    child: [
      {
        path: "/create",
        exact: true,
        sidebar: "Create",
        icon: PlusOutlined,
        name: "create",
        component: Entertainment.create,
      },
      {
        path: "/:id",
        sidebar: "Edit",
        invisible: true,
        name: "edit",
        component: Entertainment.edit,
      },
      {
        path: "",
        exact: true,
        sidebar: "List",
        icon: BarsOutlined,
        name: "list",
        component: Entertainment.list,
      },
    ],
  },
  {
    path: "/custom-banner",
    sidebar: "Custom Banner",
    name: "custombanner",
    child: [
      {
        path: "/create",
        exact: true,
        sidebar: "Create",
        icon: PlusOutlined,
        name: "create",
        component: CustomBanner.create,
      },
      {
        path: "/:id",
        sidebar: "Edit",
        invisible: true,
        name: "edit",
        component: CustomBanner.edit,
      },
      {
        path: "",
        exact: true,
        sidebar: "List",
        icon: BarsOutlined,
        name: "list",
        component: CustomBanner.list,
      },
    ],
  },

  {
    path: "/sms-service",
    sidebar: "Sms Sevice",
    name: "smsService",
    child: [
      {
        path: "/create",
        exact: true,
        sidebar: "Send SMS",
        icon: PlusOutlined,
        name: "create",
        component: SmsService.create,
      },
      // {
      //   path: '/:id',
      //   sidebar: 'Edit',
      //   invisible: true,
      //   name: 'edit',
      //   component: SmsService.edit,
      // },
      // {
      //   path: '',
      //   exact: true,
      //   sidebar: 'List',
      //   icon: BarsOutlined,
      //   name: 'list',
      //   component: SmsService.list,
      // },
    ],
  },
  {
    path: "/remote-config",
    sidebar: "RemoteConfig",
    name: "remote-config",
    icon: SettingOutlined,
    checkPermission: (permission) => permission.RemoteConfig,
    child: [
      {
        path: "/create",
        exact: true,
        sidebar: "Create",
        icon: PlusOutlined,
        name: "create",
        component: RemoteConfig.create,
      },
      {
        path: "/:id",
        sidebar: "Edit",
        invisible: true,
        name: "edit",
        component: RemoteConfig.edit,
      },
      {
        path: "",
        exact: true,
        sidebar: "List",
        icon: BarsOutlined,
        name: "list",
        component: RemoteConfig.list,
      },
    ],
  },
  {
    path: "/affise-callback",
    sidebar: "Affise CallBack",
    name: "affisecallback",
    child: [
      {
        path: "",
        exact: true,
        sidebar: "List",
        icon: BarsOutlined,
        name: "list",
        component: AffiseCallBack.list,
      },
      {
        path: "/:id",
        sidebar: "Edit",
        invisible: true,
        name: "edit",
        component: AffiseCallBack.edit,
      },
    ],
  },
  {
    path: "/video-callback",
    sidebar: "Video CallBack",
    name: "videocallback",
    child: [
      {
        path: "",
        exact: true,
        sidebar: "List",
        icon: BarsOutlined,
        name: "list",
        component: VideoCallBack.list,
      },
      {
        path: "/:id",
        sidebar: "Edit",
        invisible: true,
        name: "edit",
        component: VideoCallBack.edit,
      },
    ],
  },
  {
    path: "/news-callback",
    sidebar: "News CallBack",
    name: "newscallback",
    child: [
      {
        path: "",
        exact: true,
        sidebar: "List",
        icon: BarsOutlined,
        name: "list",
        component: NewsCallBack.list,
      },
      {
        path: "/:id",
        sidebar: "Edit",
        invisible: true,
        name: "edit",
        component: NewsCallBack.edit,
      },
    ],
  },
  {
    path: '/share-offer',
    sidebar: 'Share Offers',
    name: 'ShareOffers',
    child: [
      {
        path: '/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: ShareOffers.create,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: ShareOffers.edit,
      },
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: ShareOffers.list,
      },
    ],
  }
];

const routes = [...common, ...auth, ...cms];

const extractName = (list, _parent) => {
  const parent = { name: "", path: "", ..._parent };

  const newList = list.map((item) => {
    // if child don't need to return parent name
    if (item.child && item.child.length) {
      return extractName(item.child, item);
    }

    return {
      name: parent.name ? `${parent.name}.${item.name}` : item.name,
      path: parent.path + item.path,
    };
  });

  return newList;
};

const namedRoutes = {};
extractName(routes)
  .flat()
  .forEach((item) => {
    namedRoutes[item.name] = item.path;
  });

export const getUrl = (name, params = {}) => {
  let url = namedRoutes[name];

  Object.keys(params).forEach((key) => {
    url = url.replace(`:${key}`, params[key]);
  });

  return url;
};

export default routes;

import {
  CREATE_CUSTOM_BANNER,
  UPDATE_CUSTOM_BANNER,
  CUSTOM_BANNER_FETCHED_SUCCESS,
} from "../constants/actionTypes";

const initialState = {
  customBanners: [],
  message: "",
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CUSTOM_BANNER:
    case UPDATE_CUSTOM_BANNER:
      return {
        ...state,
        errors: action.payload.errors,
        message: action.payload.msg,
      };
    case CUSTOM_BANNER_FETCHED_SUCCESS:
      return {
        ...state,
        customBanners: action.payload.customBanners.map((item) => {
          // eslint-disable-next-line no-underscore-dangle
          const newItem = { ...item, id: item.id };
          return newItem;
        }),
        pager: action.payload.pager,
      };

    default:
      return state;
  }
};

import { message } from 'antd';

export const showErrorMessage = (err) => {
  // err.response is undefined in case of network error
  if (err.response) {
    switch (err.response.status) {
      case 422:
        return message.error(
          (err.response.data && err.response.data.msg) || 'Validation error'
        );

      case 400:
      case 403:
      case 409:
      case 500:
        return message.error(
          err.response.data.msg || err.response.data.message
        );

      case 401:
        return message
          .error(err.response.data.msg || err.response.data.message, 2)
          .then(() => message.warning('Please try again!'));

      default:
        return message.error(
          err.response.data.msg || err.response.data.message
        );
    }
  } else if (err.code === 'ECONNABORTED') {
    return message.error('Request timeout');
  } else if (navigator && !navigator.onLine) {
    return message.error('Please check your internet connection');
  } else if (err.code === 'CUSTOM') {
    return message.error(err.data);
  } else {
    return message.error('Something bad happen, try again');
  }
};

export const showSuccessMessage = (msg, duration = 2) =>
  message.success(msg, duration);

export const showLoadingMessage = (msg, duration = 0) =>
  message.loading(msg, duration);

export const showWarningMessage = (msg, duration = 2) =>
  message.warning(msg, duration);

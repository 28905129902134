import {
  CREATE_OFFER,
  UPDATE_OFFER,
  UNLOAD_OFFER,
  OFFER_FETCHED_SUCCESS,
  RESET_OFFER,
  UPDATE_ACTION_POOL,
  PUSHED_OFFER,
} from '../constants/actionTypes';

const initialState = {
  offers: [],
  actionPool: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};


export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_OFFER:
    case UPDATE_OFFER:
      return {
        ...state,
        message: action.payload.msg,
      };

    case RESET_OFFER:
      return {
        ...state,
        message: '',
      };

    case OFFER_FETCHED_SUCCESS:
      return {
        ...state,
        offers: action.payload.offers.map((item) => {
          // eslint-disable-next-line no-underscore-dangle
          const newItem = { ...item, id: item.id };
          return newItem;
        }),
        pager: action.payload.pager,
      };

    case UPDATE_ACTION_POOL:
      return {
        ...state,
        actionPool: action.payload.data,
      };

    case PUSHED_OFFER:
      return {
        ...state,
        offers: state.offers.map((item) => {
          if (item.id === action.payload.id) {
            const newItem = { ...item };
            // if (!newItem.modified_at) newItem.modified_at = action.payload.date;
            newItem.pushed_at = action.payload.date;
            return newItem;
          }
          return item;
        }),
      };

    case UNLOAD_OFFER:
      return initialState;

    default:
      return state;
  }
};

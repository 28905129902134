import  { CREATE_BANNER,
    UPDATE_BANNER,
    BANNER_FETCHED_SUCCESS
} from '../constants/actionTypes';

const initialState = {
    banners: [],
    message: '',
    pager: {
        current: 1,
        pageSize: 15,
        total: 0,
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_BANNER:
        case UPDATE_BANNER:
            return {
                ...state,
                errors: action.payload.errors,
                message: action.payload.msg
            }
        case BANNER_FETCHED_SUCCESS:
            return {
                ...state,
                banners: action.payload.banner.map((item) => {
                  // eslint-disable-next-line no-underscore-dangle
                  const newItem = { ...item, id: item.id };
                  return newItem;
                }),
                pager: action.payload.pager,
              };

        default:
            return state;
    }
}

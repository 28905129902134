import {
  CREATE_ARTICLE_PARTNER,
  UPDATE_ARTICLE_PARTNER,
  ARTICLE_PARTNER_FETCH_SUCCESS,
  ARTICLE_PARTNER_TOGGLE_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  articlePartner: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ARTICLE_PARTNER:
    case UPDATE_ARTICLE_PARTNER:
      return {
        ...state,
        message: action.payload.msg,
      };

    case ARTICLE_PARTNER_FETCH_SUCCESS:
      return {
        ...state,
        articlePartner: action.payload.articlePartner,
        pager: action.payload.pager,
      };

    case ARTICLE_PARTNER_TOGGLE_SUCCESS:
      return {
        ...state,
        articlePartner: state.articlePartner.map((partner) => {
          if (partner.id !== action.payload.id) return partner;
          const newPartner = { ...partner };
          newPartner.is_active = action.payload.active;
          return newPartner;
        }),
      };

    default:
      return state;
  }
};

import  { CREATE_TEMPLATE,
    TEMPLATE_FETCHED_SUCCESS,
    UPDATE_TEMPLATE,
} from '../constants/actionTypes';

const initialState = {
    templates: [],
    message: '',
    pager: {
        current: 1,
        pageSize: 15,
        total: 0,
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_TEMPLATE:
        case UPDATE_TEMPLATE:
            return {
                ...state,
                errors: action.payload.errors,
                message: action.payload.msg
            }

       case TEMPLATE_FETCHED_SUCCESS:
            return {
                ...state,
                templates: action.payload.templates.map((item) => {
                  // eslint-disable-next-line no-underscore-dangle
                  const newItem = { ...item, id: item.id };
                  return newItem;
                }),
                pager: action.payload.pager,
              };

        default:
            return state;
    }
}
